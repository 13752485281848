import { Box, Button, Flex, Image, Text } from '@chakra-ui/react';
import { AssignedContentResponseDTOType } from '@jam/api-sdk';
import { CaretRight, Clock } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { Chip } from '../../../common/components/Chip';
import {
	StoryStatus,
	useStoryStatus,
} from '../../../common/hooks/useStoryStatus';
import { useDifficultyIcon } from '../hooks/useDifficultyIcon';

type KeepLearningStoryProps = {
	storyId: string;
	type: AssignedContentResponseDTOType;
	title: string;
	person: string;
	role: string;
	image?: string;
	tags: string[];
	onCardClick?: () => void;
	duration?: number;
	difficulty?: string;
};

const StoryStatusToStyleMap: Record<
	StoryStatus,
	{ bg: string; color: string }
> = {
	[StoryStatus.COMPLETED]: { bg: '#EAFDEE', color: '#147129' },
	[StoryStatus.IN_PROGRESS]: { bg: '#EFF8FF', color: '#175CD3' },
	[StoryStatus.NOT_STARTED]: { bg: '#FAFAFA', color: '#383C3E' },
};

export const KeepLearningStory = ({
	storyId,
	title,
	image,
	onCardClick,
	duration,
	difficulty,
	type,
}: KeepLearningStoryProps) => {
	const { t } = useTranslation(['story', 'history']);
	const status = useStoryStatus(storyId);
	const typeText =
		type === AssignedContentResponseDTOType.refresher
			? t('history:from-refresher')
			: t('history:from-assignment');
	const { bg, color } = StoryStatusToStyleMap[status];
	const difficultyIcon = useDifficultyIcon(difficulty);

	return (
		<Flex
			direction={'column'}
			justifyContent={'space-between'}
			border={'1px'}
			w={'full'}
			maxW={'284px'}
			borderColor={'gray.200'}
			borderRadius={'2xl'}
		>
			<Flex direction={'column'} h={'full'}>
				{image && (
					<Box position={'relative'} w={'full'}>
						<Chip
							position={'absolute'}
							top="4"
							left="4"
							px="2"
							py="2px"
							h="24px"
							bg={bg}
							borderRadius={'24px'}
							border={0}
							fontSize={'14px'}
							gap={'4px'}
							color={color}
							fontWeight={'medium'}
							text={t(status)}
						/>
						{duration && (
							<Chip
								position={'absolute'}
								bottom="4"
								left="4"
								px="1"
								py="2px"
								h="24px"
								bg={'#F5F5F5'}
								border={0}
								borderRadius={'4px'}
								gap={'4px'}
								fontSize={'14px'}
								color="#757575"
								icon={<Clock size={14} color="#757575" />}
								text={t('duration_value', {
									duration,
								})}
								aria-label="Story duration"
							/>
						)}

						{difficulty && (
							<Chip
								position={'absolute'}
								bottom="4"
								right="4"
								px="1"
								py="2px"
								h="24px"
								bg={'#F5F5F5'}
								borderRadius={'4px'}
								fontSize={'14px'}
								gap={'4px'}
								color="#757575"
								border={0}
								icon={difficultyIcon ? <Image src={difficultyIcon} /> : null}
								text={t(difficulty)}
								aria-label="Story difficulty"
							/>
						)}
						<Image
							w={'full'}
							maxH={'240px'}
							borderTopRadius={'12px'}
							src={image}
							objectFit={'cover'}
						/>
					</Box>
				)}
				<Flex direction={'column'} gap="2" pt="2" px="3" h={'full'} pb={4}>
					<Flex flexGrow={1} direction={'column'} gap="2">
						<Text
							display={'flex'}
							alignItems={'center'}
							fontSize={'16px'}
							flexGrow={1}
							fontWeight={'medium'}
						>
							{title}
						</Text>
						<Text fontSize="12px" color="gray.500">
							{typeText}
						</Text>
					</Flex>
					<Button
						h="32px"
						borderColor="brand.500"
						color="brand.500"
						variant="outline"
						w={'fit-content'}
						fontSize={'14px'}
						rightIcon={<CaretRight size={14} />}
						onClick={onCardClick}
					>
						{status === StoryStatus.IN_PROGRESS
							? t('resume_mission')
							: t('start_mission')}
					</Button>
				</Flex>
			</Flex>
		</Flex>
	);
};
