import { Box, Button, Flex, Image, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { StoryLabels } from '../../../common/components/StoryLabels';
import { useGetStoryDetails } from '../../../common/hooks/useGetStoryDetails';
import {
	StoryStatus,
	useStoryStatus,
} from '../../../common/hooks/useStoryStatus';

type StoryDetailsProps = {
	storyId: string;
	journeyName?: string;
	hideAction?: boolean;
};

type ButtonProps = {
	text: string;
	bg: string;
	textColor: string;
};

const ButtonByStoryStatus: Record<StoryStatus, ButtonProps> = {
	NOT_STARTED: {
		text: 'start',
		bg: 'white',
		textColor: 'black',
	},
	IN_PROGRESS: {
		text: 'resume',
		bg: 'white',
		textColor: 'black',
	},
	COMPLETED: {
		text: 'refresh',
		bg: '#4241E4',
		textColor: 'white',
	},
};

export const StoryDetail = ({
	storyId,
	journeyName,
	hideAction = false,
}: StoryDetailsProps) => {
	const { t } = useTranslation('gym');
	const story = useGetStoryDetails(storyId);
	const status = useStoryStatus(story?.id);
	const buttonProps = ButtonByStoryStatus[status];
	const navigate = useNavigate();

	if (!story) {
		return null;
	}

	return (
		<Flex
			w="100%"
			justifyContent={'space-between'}
			border={'1px'}
			borderColor={'#d9d9d9'}
			borderRadius={'24px'}
			px={4}
			py={4}
			gap="2"
		>
			<Flex gap="2">
				<Image
					borderRadius={'12px'}
					src={
						story.persona.avatar_file.sizes.thumbnail?.url ??
						story.persona.avatar_file.url ??
						''
					}
					w="50px"
					h="50px"
				/>
				<Box>
					<Text fontSize={'14px'} fontWeight={'medium'}>
						{story.title}
					</Text>
					<Text fontSize={'12px'} color={'#757575'}>
						{`${story.persona.role} ${story.persona.workplace_name && `@${story.persona.workplace_name}`}`}
					</Text>
				</Box>
				<Box pl="3">
					<StoryLabels story={story} />
				</Box>
			</Flex>
			{!hideAction && (
				<Box>
					<Button
						id="start-story-button"
						borderRadius={'full'}
						border={'1px'}
						px="5"
						borderColor={'black'}
						size={'xs'}
						textColor={buttonProps.textColor}
						bg={buttonProps.bg}
						_hover={{ bg: buttonProps.bg }}
						onClick={() => {
							navigate(
								`/call?storyId=${story.id}&ref=gym&journey=${journeyName ?? ''}`
							);
						}}
					>
						<Text>{t(buttonProps.text)}</Text>
					</Button>
				</Box>
			)}
		</Flex>
	);
};
