import { Divider, Stack } from '@chakra-ui/react';
import { UsersSelectionTable } from './UsersSelectionTable';

export const ContentAssignmentTab = () => {
	return (
		<Stack direction="column" spacing="5" divider={<Divider />} py="10">
			<UsersSelectionTable />
		</Stack>
	);
};
