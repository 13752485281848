import { Box, Flex, Text } from '@chakra-ui/react';
import { SchemaStoryResponseDto } from '@jam/api-sdk';
import { useStoryStatus } from '../../../common/hooks/useStoryStatus';
import { StoryDetail } from './StoryDetail';

type TrackStoryRowProps = {
	number: number;
	isLast: boolean;
	story: SchemaStoryResponseDto;
	journeyName: string;
	hideAction: boolean;
};

export const TrackStoryRow = ({
	number,
	isLast,
	story,
	journeyName,
	hideAction,
}: TrackStoryRowProps) => {
	const status = useStoryStatus(story.id);

	return (
		<Flex position={'relative'} w="100%" gap="5">
			<Flex
				justifyContent={'center'}
				alignItems={'center'}
				w="55px"
				h="50px"
				my={'auto'}
				border={'1px'}
				borderColor={'#d9d9d9'}
				backgroundColor={status === 'COMPLETED' ? '#D5C9FF' : 'white'}
				borderRadius={'full'}
			>
				<Text fontSize={'14px'} color={'#757575'}>
					{number}
				</Text>
				{!isLast && (
					<Box
						position={'absolute'}
						bottom={'-50px'}
						w="5px"
						zIndex={-1}
						backgroundColor={'#D5C9FF'}
						h="60px"
					></Box>
				)}
			</Flex>
			<StoryDetail
				hideAction={hideAction}
				storyId={story.id}
				journeyName={journeyName}
			/>
		</Flex>
	);
};
