import { Flex } from '@chakra-ui/react';
import { useAuthInfo } from '@propelauth/react';
import { useTranslation } from 'react-i18next';
import { AppLayout } from '../common/components/AppLayout';
import { Salutation } from '../features/home/components/Salutation';
import { CompletedAssignments } from '../features/progress/components/CompletedAssignments';
import { CurrentAssignments } from '../features/progress/components/CurrentAssignments';

export const ProgressPage = () => {
	const { t } = useTranslation('common');
	const { user } = useAuthInfo();

	return (
		<AppLayout>
			<Salutation text={t('progress')} />
			<Flex my="10" direction={{ base: 'column' }} gap="5">
				<CurrentAssignments userId={user?.userId} />
				<CompletedAssignments userId={user?.userId} />
			</Flex>
		</AppLayout>
	);
};
