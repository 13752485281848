import {
	ChakraProps,
	Menu,
	MenuButton,
	MenuDivider,
	MenuItem,
	MenuList,
	PlacementWithLogical,
} from '@chakra-ui/react';
import { SignOut, User } from '@phosphor-icons/react';
import { useLogoutFunction } from '@propelauth/react';
import React, { cloneElement, isValidElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

type UserContextMenuProps = {
	ml?: string;
	placement: PlacementWithLogical;
	children: React.ReactNode;
};

export const UserContextMenu = ({
	children,
	ml,
	placement,
}: UserContextMenuProps) => {
	const navigate = useNavigate();
	const { t } = useTranslation('common');
	const logoutFunction = useLogoutFunction();

	return (
		<Menu placement={placement} autoSelect={false}>
			{({ isOpen }) => {
				const enhancedChildren = isValidElement<ChakraProps>(children)
					? cloneElement(children, {
							borderColor: isOpen ? 'brand.500' : '#D9D9D9',
						})
					: children;

				return (
					<>
						<MenuButton>{enhancedChildren}</MenuButton>
						<MenuList px="1" ml={ml ?? '2'} mb="6">
							<MenuItem
								onClick={() => {
									navigate('/settings');
								}}
								icon={<User size={16} />}
								borderRadius={'8px'}
								_hover={{
									bgColor: 'rgba(66, 65, 228, 0.08)',
									color: 'brand.500',
								}}
							>
								{t('personal_settings')}
							</MenuItem>
							<MenuDivider />
							<MenuItem
								onClick={() => void logoutFunction(true)}
								icon={<SignOut size={16} />}
								borderRadius={'8px'}
								_hover={{
									bgColor: 'rgba(66, 65, 228, 0.08)',
									color: 'brand.500',
								}}
							>
								{t('sign_out')}
							</MenuItem>
						</MenuList>
					</>
				);
			}}
		</Menu>
	);
};
