import {
	SchemaSessionResponseDto,
	SessionResponseDTOAnalysisState,
} from '@jam/api-sdk';
import { createApi } from '@reduxjs/toolkit/query/react';
import { buildBaseQuery } from './buildBaseQuery';

const api = createApi({
	reducerPath: 'learner-api',
	tagTypes: ['story-sessions'],
	baseQuery: buildBaseQuery('/api/v1'),
	endpoints: (builder) => ({
		getStoryLastSession: builder.query<SchemaSessionResponseDto | null, string>(
			{
				query: (id: string) => `sessions?storyReferenceIds[]=${id}`,
				transformResponse: (response: SchemaSessionResponseDto[]) => {
					return response.length > 0 ? response[0] : null;
				},
			}
		),
		getStorySessions: builder.query<SchemaSessionResponseDto[], string>({
			providesTags: ['story-sessions'],
			query: (id: string) => `sessions?storyReferenceIds[]=${id}`,
		}),
		getSession: builder.query<SchemaSessionResponseDto, string>({
			query: (id: string) => `sessions/${id}`,
		}),
		getSessionsByUserId: builder.query<SchemaSessionResponseDto[], string>({
			query: (userId: string) => `sessions?userIds[]=${userId}`,
			transformResponse: (response: SchemaSessionResponseDto[]) => {
				return response.filter(
					(session) =>
						session.analysis !== null &&
						session.analysis.state === SessionResponseDTOAnalysisState.DONE
				);
			},
		}),
		createSession: builder.mutation<
			{ id: string; call: { assistantReferenceId: string } },
			{ storyId: string }
		>({
			query: (req) => ({
				url: 'sessions',
				method: 'POST',
				body: req,
			}),
		}),
		deleteSession: builder.mutation<void, string>({
			query: (id: string) => ({
				url: `sessions/${id}`,
				method: 'DELETE',
			}),
		}),
		getUserTotalExperience: builder.query<number, void>({
			query: () => `experience/total`,
		}),
	}),
});

export const {
	useGetStorySessionsQuery,
	useLazyGetStorySessionsQuery,
	useCreateSessionMutation,
	useGetSessionsByUserIdQuery,
	useGetSessionQuery,
	useDeleteSessionMutation,
	useGetStoryLastSessionQuery,
	useLazyGetStoryLastSessionQuery,
	useGetUserTotalExperienceQuery,
	useLazyGetUserTotalExperienceQuery,
} = api;
export default api;
