import { Box } from '@chakra-ui/react';
import React from 'react';

interface Props {
	label: string;
	bgColor?: string;
	textColor?: string;
}

export const Tag: React.FC<Props> = ({ label, bgColor, textColor }) => {
	return (
		<Box
			borderRadius="24px"
			display="inline-flex"
			padding="8px"
			alignItems="center"
			gap="4px"
			flexShrink="0"
			bg={bgColor}
			color={textColor}
			fontSize="12px"
		>
			{label}
		</Box>
	);
};
