import { useEffect, useState } from 'react';
export type UsePermissionState =
	| 'granted'
	| 'denied'
	| 'prompt'
	| 'loading'
	| 'error';

export const useMicrophonePermission = () => {
	const [permissionState, setPermissionState] =
		useState<UsePermissionState>('loading');

	useEffect(() => {
		if (!navigator.permissions) {
			// Fallback for older browsers
			if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
				navigator.mediaDevices
					.getUserMedia({ audio: true })
					.then(() => {
						setPermissionState('granted');
					})
					.catch(() => {
						setPermissionState('denied');
					});
			} else {
				// Really old browsers with no mic support
				setPermissionState('denied');
			}
			return;
		}

		navigator.permissions
			.query({ name: 'microphone' as PermissionName })
			.then((result) => {
				setPermissionState(result.state);
			})
			.catch(() => {
				setPermissionState('denied');
			});
	}, []);

	const requestMicrophone = () => {
		window.navigator.mediaDevices
			.getUserMedia({ audio: true })
			.then(() => {
				setPermissionState('granted');
			})
			.catch(() => {
				setPermissionState('error');
			});
	};

	return {
		permissionState,
		requestMicrophone,
	};
};
