import {
	Flex,
	Stack,
	Tab,
	TabIndicator,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Text,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppLayout } from '../../common/components/AppLayout';
import { BreadCrumbs } from '../../common/components/BreadCrumbs';
import { UserWidgets } from '../../common/components/UserWidgets';
import { useUserOrg } from '../../common/hooks/useUserOrg';
import { TeamsFilteringToolbar } from '../../features/dashboard/components/manager/common/TeamsFilteringToolbar';
import { RoleplayAcrossUsersTable } from '../../features/dashboard/components/manager/team-activity/RoleplayAcrossUsersTable';
import { TeamActivity } from '../../features/dashboard/components/manager/team-activity/TeamActivity';
import { DashboardDateFilter } from '../../features/dashboard/types/DashboardFilteringTypes';
import { useGetOrgTeamsQuery } from '../../redux/api/dashboardApi';

export const ManagerDashboard = () => {
	const { t } = useTranslation(['dashboard', 'common']);
	const org = useUserOrg();
	const [selectedTeamId, setSelectedTeamId] = useState<string | undefined>(
		undefined
	);
	const [activeTabIndex, setActiveTabIndex] = useState(0);
	const [selectedDateFilter, setSelectedDateFilter] = useState<
		DashboardDateFilter | undefined
	>('all_time');
	const { data: teams } = useGetOrgTeamsQuery({ orgId: org?.orgId ?? '' });

	const onTabChange = (index: number) => {
		setActiveTabIndex(index);
	};

	return (
		<AppLayout>
			<BreadCrumbs
				items={[
					{
						title: t('common:activity') ?? '',
					},
					{
						title: t('dashboard:overview_tab_title') ?? '',
					},
				]}
			/>
			<Flex mt={'-8px'} justifyContent={'space-between'}>
				<Text fontWeight={'medium'} fontSize="40px">
					{t('dashboard:activity_dashboard')}
				</Text>
				<UserWidgets />
			</Flex>
			<Text fontSize="16px" mt="5" color="#757575">
				{t('dashboard:activity_dashboard_description')}
			</Text>
			<Stack direction="column" spacing="5" mt={10}>
				<TeamsFilteringToolbar
					setSelectedDateFilter={setSelectedDateFilter}
					setSelectedTeamId={setSelectedTeamId}
					teams={teams ?? []}
					showTimeFilter={activeTabIndex === 1}
				/>
			</Stack>
			<Tabs mt="10" isLazy variant={'unstyled'} onChange={onTabChange}>
				<TabList borderBottom={0}>
					<Tab
						fontWeight={'medium'}
						_selected={{ color: '#4241E4' }}
						fontSize={'16px'}
					>
						{t('dashboard:overview_tab_title')}
					</Tab>
					<Tab
						fontWeight={'medium'}
						_selected={{ color: '#4241E4' }}
						fontSize={'16px'}
					>
						{t('dashboard:all_rounds_played_tab_title')}
					</Tab>
				</TabList>
				<TabIndicator
					mt="-1.5px"
					height="2px"
					bg="#4241E4"
					borderRadius="1px"
				/>
				<TabPanels>
					<TabPanel px="0">
						<TeamActivity selectedTeamId={selectedTeamId} />
					</TabPanel>
					<TabPanel px="0">
						<RoleplayAcrossUsersTable
							selectedDateFilter={selectedDateFilter}
							teamId={selectedTeamId}
						/>
					</TabPanel>
				</TabPanels>
			</Tabs>
		</AppLayout>
	);
};
