import {
	Avatar,
	Button,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	Spinner,
	Stack,
	Text,
	useToast,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAuthInfo } from '@propelauth/react';
import { ChangeEvent, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ZodType, z } from 'zod';
import {
	useChangeAccountEmailMutation,
	useChangeAccountInfoMutation,
} from '../../../redux/api/crmApi';

const authUrl = process.env.REACT_APP_AUTH_URL ?? '';

type AccountInfo = {
	firstName?: string;
	lastName?: string;
	email: string;
	pictureUrl?: string;
};

const AccountInfoSchema: ZodType<AccountInfo> = z.object({
	firstName: z.string().min(2, 'First name must be at least 2 characters long'),
	lastName: z.string().min(2, 'Last name must be at least 2 characters long'),
	email: z.string().email('Invalid email format').min(1),
});

export const AccountDataForm = (props: AccountInfo) => {
	const { t } = useTranslation('settings');
	const { refreshAuthInfo } = useAuthInfo();
	const [changeAccountInfo] = useChangeAccountInfoMutation();
	const [changeAccountEmail] = useChangeAccountEmailMutation();
	const toast = useToast();

	const hiddenInputRef = useRef<HTMLInputElement | null>(null);

	const {
		register,
		handleSubmit,
		reset,
		formState: { isDirty, errors, isSubmitting },
	} = useForm<AccountInfo>({
		resolver: zodResolver(AccountInfoSchema),
		defaultValues: props,
	});

	const [loading, setLoading] = useState<boolean>(false);

	const uploadFileToServer = async (file: File) => {
		const formData = new FormData();
		formData.append('file', file);
		const response = await new Promise<{
			success: boolean;
		}>((resolve) => {
			const request = new XMLHttpRequest();
			request.onreadystatechange = function () {
				if (request.readyState === XMLHttpRequest.DONE) {
					if (request.status >= 200 && request.status < 300) {
						resolve({
							success: true,
						});
					} else if (400 === request.status) {
						resolve({
							success: false,
						});
					} else {
						resolve({
							success: false,
						});
					}
				}
			};
			request.open('post', `${authUrl}/api/fe/v1/update_profile_image`);
			request.withCredentials = true;
			request.setRequestHeader('X-CSRF-Token', '-.-');
			request.send(formData);
		});

		return response;
	};

	const handleUploadedFile = async (event: ChangeEvent<HTMLInputElement>) => {
		if (!event.target.files) return;
		const file = event.target.files[0];
		setLoading(true);
		const { success } = await uploadFileToServer(file);

		if (success) {
			await refreshAuthInfo();
		}
		setLoading(false);
		toast({
			title: success ? t('picture-uploaded') : t('error'),
			status: success ? 'success' : 'error',
			duration: 3000,
			isClosable: true,
		});
		if (hiddenInputRef.current) {
			hiddenInputRef.current.value = '';
		}
	};

	const onUpload = () => {
		hiddenInputRef.current?.click();
	};

	const onSubmit = async (data: AccountInfo) => {
		await changeAccountInfo({
			firstName: data.firstName ?? '',
			lastName: data.lastName ?? '',
		});
		await changeAccountEmail({
			newEmail: data.email,
		});

		toast({
			title: t('account_info_updated'),
			status: 'success',
			duration: 3000,
			isClosable: true,
		});
	};

	return (
		<Flex direction={'column'}>
			<Text fontWeight={'medium'} fontSize={'24px'} color={'#757575'}>
				{t('account-title')}
			</Text>
			<Flex alignItems={'center'} gap="5" mt="5">
				{loading ? (
					<Flex
						borderRadius={'full'}
						justifyContent={'center'}
						alignItems={'center'}
						height={'96px'}
						width={'96px'}
						bgColor="grey"
					>
						<Spinner color="white" size="lg" />
					</Flex>
				) : (
					<Avatar
						icon={<Spinner size="xl" />}
						size={'xl'}
						src={props.pictureUrl ?? ''}
						name={props.firstName ?? ''}
						bg="grey"
					/>
				)}
				<Input
					role="file-upload"
					type="file"
					ref={hiddenInputRef}
					onChange={(e) => void handleUploadedFile(e)}
					hidden
				/>
				<Text
					onClick={onUpload}
					cursor={'pointer'}
					textDecoration={'underline'}
					color="#757575"
				>
					Edit
				</Text>
			</Flex>
			<Stack mt="8" spacing={4} maxW={'500px'}>
				<FormControl isInvalid={!!errors.firstName}>
					<FormLabel>{t('first-name')}</FormLabel>
					<Input borderRadius={'full'} {...register('firstName')} />
					{errors.firstName?.message && (
						<FormErrorMessage>{errors.firstName.message}</FormErrorMessage>
					)}
				</FormControl>
				<FormControl isInvalid={!!errors.lastName}>
					<FormLabel>{t('last-name')}</FormLabel>
					<Input borderRadius={'full'} {...register('lastName')} />
					{errors.lastName?.message && (
						<FormErrorMessage>{errors.lastName.message}</FormErrorMessage>
					)}
				</FormControl>
				<FormControl isInvalid={!!errors.email}>
					<FormLabel>{t('email-address')}</FormLabel>
					<Input borderRadius={'full'} {...register('email')} />
					{errors.email?.message && (
						<FormErrorMessage>{errors.email.message}</FormErrorMessage>
					)}
				</FormControl>
				<Flex mt="4" gap="5">
					<Button
						id="save-user-info-button"
						borderRadius={'full'}
						type="submit"
						isLoading={isSubmitting}
						px="6"
						bgColor={isDirty ? 'brand.500' : 'gray.500'}
						size="md"
						onClick={() => {
							if (!isDirty) return;
							void handleSubmit(onSubmit)();
						}}
					>
						{t('save')}
					</Button>
					<Button
						type="reset"
						color={'black'}
						bg="transparent"
						colorScheme="red"
						size="md"
						onClick={() => {
							reset();
						}}
					>
						{t('cancel')}
					</Button>
				</Flex>
			</Stack>
		</Flex>
	);
};
