import { Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { Pagination } from './Pagination';

type Props = {
	title?: string;
	topbarContent?: React.ReactNode;
	showPagination?: boolean;
	rowsPerPage: number;
	page: number;
	numberOfPages: number;
	setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
	setPage: React.Dispatch<React.SetStateAction<number>>;
};

const CustomTableHeader = ({
	showPagination,
	title,
	topbarContent,
	rowsPerPage,
	setRowsPerPage,
	page,
	setPage,
	numberOfPages,
}: Props) => {
	return (
		<Stack
			direction="row"
			alignItems="center"
			width={'100%'}
			justifyContent={title || topbarContent ? 'space-between' : 'flex-end'}
		>
			{title && <Text fontWeight="bold">{title}</Text>}
			{topbarContent && topbarContent}
			{showPagination && (
				<Pagination
					page={page}
					setPage={setPage}
					rowsPerPage={rowsPerPage}
					numberOfPages={numberOfPages}
					setRowsPerPage={setRowsPerPage}
				/>
			)}
		</Stack>
	);
};

export default CustomTableHeader;
