import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { AppLayout } from '../../common/components/AppLayout';
import { BreadCrumbs } from '../../common/components/BreadCrumbs';
import { useGetStoryDetails } from '../../common/hooks/useGetStoryDetails';
import { MissionDetail } from '../../features/manager-journeys/components/MissionDetail';
import { useGetJourneyQuery } from '../../redux/api/cmsApi';

export const ManagerMissionDetailPage = () => {
	const { t } = useTranslation('common');
	const { journeyId, missionId } = useParams();
	const { data: journey } = useGetJourneyQuery(journeyId as string, {
		skip: !journeyId,
	});
	const mission = useGetStoryDetails(missionId);

	if (!journey || !mission) {
		return null;
	}

	return (
		<AppLayout>
			<BreadCrumbs
				withUserWidget
				backLink={`/journey/${journey.id}`}
				items={[
					{
						title: t('tracks'),
						link: '/journeys',
					},
					{
						title: journey.title,
						link: `/journey/${journey.id}`,
					},
					{
						title: mission.title,
						link: `/journey/${journey.id}/mission/${mission.id}`,
					},
				]}
			/>
			<MissionDetail />
		</AppLayout>
	);
};
