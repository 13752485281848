import React from 'react';
import { Flex } from '@chakra-ui/react';
import CustomTable from '../../../../../common/components/custom-table/CustomTable';
import { SchemaTeamResponseDto, SchemaUserOverviewDto } from '@jam/api-sdk';
import { OrgMemberInfo } from '../../../../../common/types/AuthTypes';
import { useNavigate } from 'react-router-dom';
import { RegisteredMembersTopBar } from './RegisteredMembersTopBar';
import { useRegisteredMembersTable } from './useRegisteredMembersTable';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

import { useTranslation } from 'react-i18next';
import { UserLastSessionDateCell } from '../../../../../common/components/custom-table/cells/user/UserLastSessionDateCell';
import { UserTotalSessionsCell } from '../../../../../common/components/custom-table/cells/user/UserTotalSessionsCell';
import { CreatedAtCell } from '../../../../../common/components/custom-table/cells/common/CreatedAtCell';
import { UserTeamCell } from '../../../../../common/components/custom-table/cells/user/UserTeamCell';
import { UserRoleCell } from '../../../../../common/components/custom-table/cells/user/UserRoleCell';
import { UserAvatarCell } from '../../../../../common/components/custom-table/cells/user/UserAvatarCell';

interface Props {
	org: OrgMemberInfo | undefined;
	teams: SchemaTeamResponseDto[];
}

export const RegisteredMembers: React.FC<Props> = ({ org, teams }) => {
	const { t } = useTranslation(['common', 'team']);
	const navigate = useNavigate();

	const {
		isLoading,
		data,
		search,
		setSearch,
		setSelectedTeamId,
		customFilter,
		onRoleChange,
	} = useRegisteredMembersTable({ org });

	const columnHelper = createColumnHelper<SchemaUserOverviewDto>();
	// Components defined in a separate file -> make sure this file stays small and readable
	const columns = [
		columnHelper.accessor('email', {
			header: t('common:user').toString(),
			cell: ({ row }) => <UserAvatarCell row={row.original} />,
		}),
		columnHelper.accessor('role', {
			header: t('common:role').toString(),
			cell: ({ row }) => (
				<UserRoleCell row={row.original} onRoleChange={onRoleChange} />
			),
		}),
		columnHelper.accessor('teams', {
			header: t('team:team').toString(),
			cell: ({ row }) => <UserTeamCell row={row.original} />,
		}),
		columnHelper.accessor('createdAt', {
			header: t('team:member_since').toString(),
			cell: ({ row }) => <CreatedAtCell row={row.original} />,
		}),
		columnHelper.accessor('totalSessions', {
			header: t('common:rounds').toString(),
			cell: ({ row }) => <UserTotalSessionsCell row={row.original} />,
		}),
		columnHelper.accessor('lastSessionDate', {
			header: t('common:last_round_date').toString(),
			cell: ({ row }) => <UserLastSessionDateCell row={row.original} />,
		}),
	];
	if (!org || isLoading) return null;

	return (
		<Flex gap={6} direction={'column'}>
			<CustomTable<SchemaUserOverviewDto>
				data={data ?? []}
				columns={columns as unknown as ColumnDef<SchemaUserOverviewDto, any>[]}
				onRowClick={(item) => {
					navigate(`/dashboard/user/${item.id}`);
				}}
				customFilters={customFilter}
				topbarContent={
					<RegisteredMembersTopBar
						teams={teams}
						search={search}
						setSearch={setSearch}
						setSelectedTeamId={setSelectedTeamId}
					/>
				}
				withWrapper
				paginationPosition="bottom"
			/>
		</Flex>
	);
};
