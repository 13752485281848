import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@chakra-ui/react';
import { dateAgoAsString } from '../../../../utils/dateUtil';

export const UserLastSessionDateCell: React.FC<{
	row: {
		lastSessionDate?: string;
	};
}> = ({ row }) => {
	const { t } = useTranslation();
	if (!row.lastSessionDate) return null;
	return <Text>{dateAgoAsString(t, row.lastSessionDate)}</Text>;
};
