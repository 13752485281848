import { skipToken } from '@reduxjs/toolkit/query';
import { useTranslation } from 'react-i18next';
import { useGetCompletedAssignedContentAllocationQuery } from '../../../redux/api/contentAllocationApi';
import { AssignmentsList } from './AssignmentsList';

export const CompletedAssignments = ({
	userId,
	hideAction,
}: {
	userId?: string;
	hideAction?: boolean;
}) => {
	const { t } = useTranslation('gym');
	const { data: contentAllocations } =
		useGetCompletedAssignedContentAllocationQuery(userId ?? skipToken);

	return (
		<AssignmentsList
			hideAction={hideAction}
			title={t('completed_assignments')}
			contentAllocations={contentAllocations}
		/>
	);
};
