import { CircularProgress, Flex, Image, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

type FeedbackNotFinishedProps = {
	status: 'PENDING' | 'STALLED';
};

const textToStatusMap: Record<FeedbackNotFinishedProps['status'], string> = {
	PENDING: 'feedback_not_started_text',
	STALLED: 'feedback_error_stalled_text',
};

export const FeedbackNotFinished = ({ status }: FeedbackNotFinishedProps) => {
	const { t } = useTranslation('call');

	return (
		<Flex
			h="100%"
			direction="column"
			gap="10"
			justifyContent={'center'}
			alignItems={'center'}
		>
			{status === 'PENDING' ? (
				<CircularProgress
					size="200px"
					isIndeterminate
					color="green.400"
					thickness={'3px'}
				/>
			) : (
				<Image src="/dark-logo.svg" w="60px" />
			)}
			<Text color="#000000CC" size="lg">
				{t(textToStatusMap[status])}
			</Text>
		</Flex>
	);
};
