import { Stack } from '@chakra-ui/react';
import { PreparedFilteringOptions } from '../../types/FilteringTypes';
import { useEffect, useRef, useState } from 'react';
import { FilteringElement } from './FilteringElement';
import { Select } from '../core/select/Select';

export interface FilteringToolbarProps {
	filters: PreparedFilteringOptions[];
	/**
	 * Can add more customizations in the future + better UI for additional filters, for now not needed
	 * TLDR; Implement allowAddFilter before using it (as of 19.11.2024)
	 */
	allowAddFilter?: boolean;
}

/**
 * @param filters
 * @param allowAddFilter
 * @constructor
 */
export const FilteringToolbar = ({
	filters = [],
	allowAddFilter = false,
}: FilteringToolbarProps) => {
	const [shownFiltersIds, setShownFiltersIds] = useState<string[]>([]);
	const initiallyLoaded = useRef<boolean>(false);

	useEffect(() => {
		if (initiallyLoaded.current || !filters?.length) {
			return;
		}

		setShownFiltersIds(
			(filters?.filter((filter) => !filter.isExtra) ?? []).map(
				(filter) => filter.id
			)
		);
		initiallyLoaded.current = true;
	}, [filters]);

	const onAddFilter = (filter: PreparedFilteringOptions) => {
		setShownFiltersIds([...shownFiltersIds, filter.id]);
	};

	// UI Control
	const notAddedFilters = filters.filter(
		(filter) => !shownFiltersIds.includes(filter.id)
	);
	const showAddFilter = allowAddFilter && notAddedFilters.length > 0;
	const toShowFilters = filters.filter((filter) =>
		shownFiltersIds.includes(filter.id)
	);

	return (
		<Stack direction="row" gap={1} className="w-full" alignItems="center">
			{toShowFilters.map((filter) => {
				return <FilteringElement filter={filter} key={filter.id} />;
			})}

			{showAddFilter && (
				<Select
					placeholder="Add filter"
					size="sm"
					borderRadius="10px"
					maxW="fit-content"
					border="none"
					onChange={(e) => {
						const selectedFilter = notAddedFilters.find(
							(filter) => filter.id === e.target.value
						);
						if (selectedFilter) {
							onAddFilter(selectedFilter);
						}
					}}
				>
					{notAddedFilters.map((filter) => (
						<option key={filter.id} value={filter.id}>
							{filter.id}
						</option>
					))}
				</Select>
			)}
		</Stack>
	);
};
