import {
	Avatar,
	Box,
	Button,
	Divider,
	Flex,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Stack,
	Text,
	Tooltip,
} from '@chakra-ui/react';
import { SchemaCompletedUserActivityReposeDto } from '@jam/api-sdk';
import { DotsThreeVertical, Info } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

type Props = {
	title: string;
	tooltip?: string;
	headers: string[];

	usersActivity:
		| SchemaCompletedUserActivityReposeDto['mostActive']
		| SchemaCompletedUserActivityReposeDto['leastActive'];
};

const LIMIT = 3;

export const UsersList = ({
	title,
	tooltip,
	headers,
	usersActivity,
}: Props) => {
	const { t } = useTranslation(['dashboard', 'common']);
	const [seeAllExpanded, setSeeAllExpanded] = useState<boolean>(false);
	const navigate = useNavigate();

	const onToggleSeeAll = () => {
		setSeeAllExpanded((prev) => !prev);
	};

	const showExpandButton = usersActivity.length > LIMIT;
	return (
		<Box
			border={'1px'}
			borderColor="#D9D9D9"
			borderRadius={'16px'}
			w="100%"
			p="6"
		>
			<Stack spacing={'3'} divider={<Divider />}>
				<Flex alignItems="center" w="100%" pr="3">
					<Box flexShrink={1} flex="0.6" flexDirection="row">
						<Text variant="body" fontWeight="bold" fontSize="16px">
							{title}
						</Text>
						{tooltip && (
							<Tooltip label={tooltip}>
								<Info size={20} />
							</Tooltip>
						)}
					</Box>
					{headers.map((header) => {
						return (
							<Text
								key={header}
								textAlign={'center'}
								flexShrink={0}
								flex="0.2"
								fontSize={'12px'}
								color={'#757575'}
							>
								{header}
							</Text>
						);
					})}
				</Flex>
				{usersActivity
					.slice(0, seeAllExpanded ? usersActivity.length : LIMIT)
					.map((userActivity) => {
						return (
							<Flex
								onClick={() => {
									navigate(`/dashboard/user/${userActivity.userId}`);
								}}
								cursor={'pointer'}
								alignItems="center"
								w="100%"
								key={userActivity.userId}
							>
								<Flex alignItems={'center'} flexShrink={1} flex="0.6" gap="3">
									<Avatar
										size="sm"
										name={userActivity.userName ?? 'N/A'}
										src={userActivity.userPictureUrl}
										bg="gray"
									/>
									<Box>
										<Text fontSize="16px" fontWeight="medium">
											{userActivity.userName ?? 'Unknown User'}
										</Text>
										<Text fontSize="14px" color="#757575">
											{userActivity.organizationName}
										</Text>
									</Box>
								</Flex>
								<Text
									textAlign={'center'}
									flexShrink={0}
									flex="0.2"
									fontSize={'30px'}
								>
									{userActivity.sessionCompletedLastWeek}
								</Text>
								<Flex justifyContent={'center'} flexShrink={0} flex="0.2">
									<Box
										w="fit-content"
										bg="#F5F5F5"
										px="2"
										py="1"
										borderRadius={'20px'}
									>
										<Text>{userActivity.sessionsCompletedTotal}</Text>
									</Box>
								</Flex>
								<Menu>
									<MenuButton>
										<DotsThreeVertical size={15} />
									</MenuButton>
									<MenuList>
										<MenuItem onClick={() => {}}>
											<Text>{t('common:see_details')}</Text>
										</MenuItem>
									</MenuList>
								</Menu>
							</Flex>
						);
					})}
				{showExpandButton && (
					<Button
						onClick={onToggleSeeAll}
						width="100%"
						backgroundColor="#F5F5F5"
						borderRadius="8px"
						color="#0B1215"
					>
						{seeAllExpanded ? t('dashboard:hide_all') : t('dashboard:see_all')}
					</Button>
				)}
			</Stack>
		</Box>
	);
};
