import { Box, Flex } from '@chakra-ui/react';
import { SchemaJourneyResponseDto } from '@jam/api-sdk';
import { MissionCard } from './MissionCard';

type JourneyMissionListProps = {
	journeyId: string;
	missions: SchemaJourneyResponseDto['stories'];
};

export const JourneyMissionList = ({
	missions,
	journeyId,
}: JourneyMissionListProps) => {
	return (
		<Flex direction={'column'} gap={4} position="relative">
			{missions.map((mission, index) => (
				<Flex w={'100%'} key={index} gap={4} position="relative">
					{index < missions.length - 1 && (
						<Box
							position="absolute"
							left="22px"
							top="60px"
							bottom="-20px"
							width="4px"
							bg="#D9D9D9"
							zIndex={0}
						/>
					)}
					<Flex
						mt={2}
						borderWidth={'2px'}
						borderColor={'#D9D9D9'}
						color={'#757575'}
						bg="#FAFAFA"
						fontSize={'16px'}
						w={'48px'}
						h={'48px'}
						justifyContent={'center'}
						alignItems={'center'}
						borderRadius={'full'}
						fontWeight={'500'}
						position="relative"
						zIndex={1}
					>
						{index + 1}
					</Flex>
					<Flex flexGrow={1}>
						<MissionCard mission={mission} journeyId={journeyId} />
					</Flex>
				</Flex>
			))}
		</Flex>
	);
};
