import { useAuthInfo } from '@propelauth/react';

export const useIsOnboarding = (feature: string) => {
	const { user } = useAuthInfo();
	// get the onboarding string from the user properties
	const userProperties = (user?.properties?.onboarding as string) ?? '';
	// split the onboarding string into an array
	const userPropertiesArray = userProperties?.split(',');
	// check if the user is onboarded to the feature
	const isOnboarding = !userPropertiesArray.includes(feature);
	return isOnboarding;
};
