import {
	SchemaCreateLearnerSessionFeedbackDto,
	SchemaCreateLearnerStoryItemFeedbackRequestDto,
	SchemaLearnerStoryFeedbackResponseDto,
	SchemaLearnerStoryItemFeedbackResponseDto,
} from '@jam/api-sdk';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../store';

const api = createApi({
	reducerPath: 'user-feedback-api',
	tagTypes: ['user-feedback', 'user-item-feedback'],
	baseQuery: fetchBaseQuery({
		baseUrl: `${
			process.env
				.REACT_APP_SALES_ENABLEMENT_PLATFORM_NEST_API_BASE_URL as string
		}/api/v1/user-feedback`,
		prepareHeaders: (headers, { getState }) => {
			const token = (getState() as RootState).applicationState.accessToken;

			if (token) {
				headers.set('Authorization', `Bearer ${token}`);
			}

			return headers;
		},
	}),
	endpoints: (builder) => ({
		getItemUserFeedbackBySessionId: builder.query<
			SchemaLearnerStoryItemFeedbackResponseDto[],
			string
		>({
			providesTags: ['user-item-feedback'],
			query: (sessionId: string) => `learner/story/item?sessionId=${sessionId}`,
		}),
		getStoryUserFeedback: builder.query<
			SchemaLearnerStoryFeedbackResponseDto[],
			string
		>({
			providesTags: ['user-feedback'],
			query: (sessionId: string) =>
				`learner/story?storyReferenceId=${sessionId}`,
		}),
		createSessionUserFeedback: builder.mutation<
			void,
			SchemaCreateLearnerSessionFeedbackDto
		>({
			query: (dto: SchemaCreateLearnerSessionFeedbackDto) => ({
				url: 'learner/session',
				method: 'POST',
				body: { ...dto },
			}),
		}),
		createItemUserFeedback: builder.mutation<
			SchemaCreateLearnerStoryItemFeedbackRequestDto,
			{
				content?: string;
				sessionId: string;
				storyItemReferenceId: string;
				positive: boolean;
				preset?: string[];
			}
		>({
			invalidatesTags: ['user-item-feedback'],
			query: (req) => ({
				url: `learner/story/item`,
				method: 'POST',
				body: req,
			}),
		}),
		createStoryUserFeedback: builder.mutation<
			{ id: string },
			{
				storyReferenceId: string;
				rating: number;
				content: string;
			}
		>({
			invalidatesTags: ['user-feedback'],
			query: (req) => ({
				url: `learner/story`,
				method: 'POST',
				body: req,
			}),
		}),
	}),
});

export const {
	useGetItemUserFeedbackBySessionIdQuery,
	useGetStoryUserFeedbackQuery,
	useCreateItemUserFeedbackMutation,
	useCreateSessionUserFeedbackMutation,
	useCreateStoryUserFeedbackMutation,
} = api;
export default api;
