import { Flex } from '@chakra-ui/react';
import { AllTimeCompletions } from './overview/AllTimeCompletions';
import { KeyImprovementStories } from './overview/KeyImprovementStories';
import { RecentActivity } from './overview/RecentActivity';

export const UserDetailsOverview = () => {
	return (
		<Flex direction={'column'} gap="7" my="6">
			<AllTimeCompletions />
			<RecentActivity />
			<KeyImprovementStories />
		</Flex>
	);
};
