import { Button, Text, Tooltip } from '@chakra-ui/react';
import { Play } from '@phosphor-icons/react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useStoryStatus } from '../../../common/hooks/useStoryStatus';
import { useReferer } from '../../home/hooks/useReferer';
import { useNextStep } from '../hooks/useNextStep';

type NextStoryProps = {
	storyId: string;
	isPrimary: boolean;
};

export const NextStory = ({ storyId, isPrimary }: NextStoryProps) => {
	const nextStep = useNextStep();
	const { t } = useTranslation(['call', 'story']);
	const storyStatus = useStoryStatus(storyId);
	const { ref } = useReferer();

	const onNextStoryClick = useCallback(() => {
		if (nextStep) {
			window.location.assign(nextStep.nextStoryUrl);
		}
	}, [nextStep, ref]);

	if (!nextStep) return null;

	const nextButtonTooltip =
		storyStatus === 'COMPLETED'
			? t('next_mission-tooltip-completed')
			: t('next_mission-tooltip');

	let nextMissionLabel = t('next_mission');
	if (nextStep && nextStep.action === 'back') {
		if (ref === 'home') {
			nextMissionLabel = t('back_to_home');
		} else if (ref === 'gym') {
			nextMissionLabel = t('back_to_all_missions');
		} else if (ref === 'history') {
			nextMissionLabel = t('back_to_history');
		} else {
			nextMissionLabel = t('back_to_home');
		}
	}

	return (
		<Tooltip
			p={3}
			lineHeight={'21px'}
			hasArrow
			isDisabled={nextStep.action === 'back'}
			maxW={'200px'}
			placement="top"
			bg={'#0B1215'}
			borderRadius={'24px'}
			fontSize={'12px'}
			border={'1px solid #E2E8F0'}
			label={nextButtonTooltip}
		>
			<Button
				id="next-story-button"
				mr="2"
				borderRadius={'16px'}
				textColor={isPrimary ? 'white' : '#4241E4'}
				borderColor={isPrimary ? '#4241E4' : 'white'}
				bg={isPrimary ? '#4241E4' : 'white'}
				gap={'8px'}
				border={'1px'}
				_hover={{
					bg: isPrimary ? '#4241E4' : 'white',
				}}
				onClick={() => void onNextStoryClick()}
			>
				<Text>{nextMissionLabel}</Text>
				{nextStep.action === 'nextStory' && <Play weight="bold" size={14} />}
			</Button>
		</Tooltip>
	);
};
