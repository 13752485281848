/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	Flex,
	Stack,
	TableColumnHeaderProps,
	TableProps,
} from '@chakra-ui/react';
import {
	ColumnDef,
	ColumnFiltersState,
	OnChangeFn,
	RowSelectionState,
	SortingState,
	getCoreRowModel,
	getFacetedMinMaxValues,
	getFacetedRowModel,
	getFacetedUniqueValues,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
} from '@tanstack/react-table';
import React, { useEffect, useState } from 'react';
import CustomTableFooter from './CustomTableFooter';
import CustomTableHeader from './CustomTableHeader';
import TableComponent from './TableComponent';

export type CustomTableProps<T> = {
	data: T[];
	columns: ColumnDef<T, any>[];
	customFilters?: ColumnFiltersState;
	defaultSorting?: SortingState;
	onRowClick?: (row: T) => void;
	tableProps?: TableProps;
	headerProps?: TableColumnHeaderProps;
	paginationPosition?: 'top' | 'bottom' | 'both' | 'none';
	title?: string;
	withWrapper?: boolean;
	topbarContent?: React.ReactNode;
	onRowSelectionChange?: OnChangeFn<RowSelectionState>;
	rowSelection?: RowSelectionState;
};

const CustomTable = <T,>({
	columns,
	data,
	customFilters,
	defaultSorting,
	tableProps,
	headerProps,
	onRowClick = () => {},
	paginationPosition = 'bottom',
	title,
	withWrapper = false,
	topbarContent,
	onRowSelectionChange,
	rowSelection,
}: CustomTableProps<T>) => {
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [page, setPage] = useState(1);
	const table = useReactTable({
		getPaginationRowModel: getPaginationRowModel(),
		columns,
		initialState: {
			sorting: defaultSorting,
		},
		state: {
			rowSelection,
			columnFilters: customFilters,
			pagination: {
				pageIndex: page - 1,
				pageSize: rowsPerPage,
			},
		},
		data,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getFilteredRowModel: getFilteredRowModel(), // needed for client-side filtering
		getFacetedRowModel: getFacetedRowModel(), // client-side faceting
		getFacetedUniqueValues: getFacetedUniqueValues(), // generate unique values for select filter/autocomplete
		getFacetedMinMaxValues: getFacetedMinMaxValues(), // generate min/max values for range filter
		onRowSelectionChange,
	});

	useEffect(() => {
		setPage(1);
	}, [customFilters]);

	const showTopPagination =
		paginationPosition === 'top' || paginationPosition === 'both';
	const showBottomPagination =
		paginationPosition === 'bottom' || paginationPosition === 'both';

	let tableComponent = (
		<TableComponent
			table={table}
			onRowClick={onRowClick}
			tableProps={tableProps}
			headerProps={headerProps}
		/>
	);

	if (withWrapper) {
		tableComponent = (
			<Stack
				border={'1px solid #D9D9D9'}
				borderRadius={'16px'}
				padding={'24px'}
				overflowX="auto"
			>
				{tableComponent}
			</Stack>
		);
	}
	return (
		<Flex direction="column" overflowX={'auto'} gap={8}>
			<CustomTableHeader
				title={title}
				topbarContent={topbarContent}
				showPagination={showTopPagination}
				rowsPerPage={rowsPerPage}
				page={page}
				numberOfPages={table.getPageCount()}
				setPage={setPage}
				setRowsPerPage={setRowsPerPage}
			/>
			{tableComponent}
			<CustomTableFooter
				showPagination={showBottomPagination}
				rowsPerPage={rowsPerPage}
				page={page}
				numberOfPages={table.getPageCount()}
				setPage={setPage}
				setRowsPerPage={setRowsPerPage}
			/>
		</Flex>
	);
};

export default CustomTable;
