import { StringParam, useQueryParam, withDefault } from 'use-query-params';

export const useActiveStory = () => {
	const [activeStory, setActiveStory] = useQueryParam<string | null>(
		'storyId',
		withDefault(StringParam, null)
	);

	return { activeStory, setActiveStory };
};
