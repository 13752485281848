import { StringParam, useQueryParam, withDefault } from 'use-query-params';

export const useReferer = () => {
	const [ref] = useQueryParam<string | null>(
		'ref',
		withDefault(StringParam, null)
	);

	return { ref };
};
