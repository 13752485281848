import {
	Avatar,
	Divider,
	Flex,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Text,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useGetManageableUsersQuery } from '../../../../../redux/api/crmApi';

type UserSelectModalProps = {
	isOpen: boolean;
	onClose: () => void;
};

export const UserSelectModal = ({ isOpen, onClose }: UserSelectModalProps) => {
	const { t } = useTranslation('common');
	const [search, setSearch] = useState('');
	const nav = useNavigate();
	const { data } = useGetManageableUsersQuery();

	if (!data) return null;
	const users = data.filter((user) => {
		const fullName = `${user.firstName ?? ''} ${user.lastName ?? ''}`;
		return fullName.toLowerCase().includes(search.toLowerCase());
	});

	const onCloseModal = () => {
		setSearch('');
		onClose();
	};

	return (
		<Modal isOpen={isOpen} onClose={onCloseModal}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>{t('change_user')}</ModalHeader>
				<ModalCloseButton />
				<ModalBody pb={6}>
					<Input
						value={search}
						onChange={(e) => setSearch(e.target.value)}
						borderRadius={'8px'}
						placeholder={t('search_by_name') ?? ''}
					/>
					<Divider my="3" />
					<Flex direction="column" gap="3" maxH={'500px'} overflowY="auto">
						{users.map((user) => (
							<Flex
								cursor={'pointer'}
								onClick={() => {
									nav(`/dashboard/user/${user.id}`);
									onCloseModal();
								}}
								key={user.id}
								alignItems="center"
								gap="1"
							>
								<Avatar
									name={`${user.firstName ?? ''} ${user.lastName ?? ''}`}
									size="sm"
									bg="grey"
									src={user.pictureUrl ?? undefined}
								/>
								<Text fontSize="16px" fontWeight="medium">
									{user.firstName} {user.lastName}
								</Text>
							</Flex>
						))}
					</Flex>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};
