import { useDisclosure, useToast } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMicrophonePermission } from '../hooks/useMicrophonePermission';
import MicPermissionModal from './MicPermissionModal';

export const MicrophonePermission = () => {
	const { t } = useTranslation(['call', 'story']);
	const { permissionState, requestMicrophone } = useMicrophonePermission();
	const toast = useToast();
	const { isOpen, onOpen, onClose } = useDisclosure();

	useEffect(() => {
		if (permissionState === 'denied' || permissionState === 'prompt') {
			onOpen();
			return;
		}

		if (permissionState === 'error') {
			toast({
				title: t('mic_permissions_error_title'),
				description: t('mic_permissions_error_text'),
				status: 'error',
				duration: 9000,
				isClosable: true,
			});
		}
	}, [permissionState]);

	const onMicPermissionModalClose = () => {
		onClose();
		requestMicrophone();
	};

	return (
		<MicPermissionModal isOpen={isOpen} onClose={onMicPermissionModalClose} />
	);
};
