import { Button, Flex, Text } from '@chakra-ui/react';
import { ArrowRight } from '@phosphor-icons/react';
import { useAuthInfo } from '@propelauth/react';
import { StepType, useTour } from '@reactour/tour';
import { useTranslation } from 'react-i18next';
import { useCompleteOnboardingMutation } from '../../../redux/api/crmApi';
import { OnboardingFeature } from '../../types/Onboarding';

type OnboardingNavigationProps = {
	feature: OnboardingFeature;
};

const STEP_CTA_PER_FEATURE: Record<
	OnboardingFeature,
	(
		steps: StepType[],
		currentStep: number
	) => {
		text: string;
		showArrow: boolean;
	}
> = {
	[OnboardingFeature.HOME_PAGE]: () => {
		return {
			text: 'next',
			showArrow: true,
		};
	},
	[OnboardingFeature.CALL_PAGE]: (steps: StepType[], currentStep: number) => {
		const numberOfSteps = steps.length;
		const currentStepIndex = currentStep + 1;
		const isLastStep = currentStepIndex === numberOfSteps;
		return {
			text: isLastStep ? 'understood' : 'next',
			showArrow: !isLastStep,
		};
	},
};

const STEP_ACTIONS_PER_FEATURE: Record<
	OnboardingFeature,
	((steps: StepType[], currentStep: number) => void) | undefined
> = {
	[OnboardingFeature.HOME_PAGE]: (steps: StepType[], currentStep: number) => {
		const selector = steps[currentStep].selector as string;
		const currentStepIndex = currentStep + 1;
		if (currentStepIndex === 3) {
			const element = document.querySelector(selector);
			if (element) {
				(element as HTMLElement).click();
			}
		}
	},
	[OnboardingFeature.CALL_PAGE]: (steps: StepType[], currentStep: number) => {
		const selector = steps[currentStep].selector as string;
		const currentStepIndex = currentStep + 1;
		if (currentStepIndex === 1) {
			const element = document.querySelector(selector);
			if (element) {
				// click on the feedback tab
				(element as HTMLElement).click();
			}
		}
	},
};

export const OnboardingNavigation = ({
	feature,
}: OnboardingNavigationProps) => {
	const { t } = useTranslation('common');
	const { refreshAuthInfo } = useAuthInfo();
	const { currentStep, setCurrentStep, steps } = useTour();
	const [completeOnboarding] = useCompleteOnboardingMutation();
	const numberOfSteps = steps.length;
	const currentStepIndex = currentStep + 1;
	const isLastStep = currentStepIndex === numberOfSteps;
	const handleNextStep = async () => {
		const action = STEP_ACTIONS_PER_FEATURE[feature];
		if (action) {
			action(steps, currentStep);
		}
		setCurrentStep(currentStep + 1);
		if (isLastStep) {
			await completeOnboarding({ feature });
			await refreshAuthInfo();
		}
	};

	const stepCta = STEP_CTA_PER_FEATURE[feature](steps, currentStep);

	return (
		<Flex mt={3} alignItems="center" justifyContent="space-between">
			<Text fontFamily={'14px'} fontWeight={'500'}>
				{numberOfSteps > 1 && `${currentStepIndex}/${numberOfSteps}`}
			</Text>
			<Button
				color={'#4241E4'}
				bg={'white'}
				size={'sm'}
				rightIcon={stepCta.showArrow ? <ArrowRight /> : undefined}
				onClick={() => {
					void handleNextStep();
				}}
			>
				{t(stepCta.text)}
			</Button>
		</Flex>
	);
};
