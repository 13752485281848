import {
	SchemaAssignedContentResponseDto,
	SchemaCreateAssignContentRequestDto,
} from '@jam/api-sdk';
import { createApi } from '@reduxjs/toolkit/query/react';
import { buildBaseQuery } from './buildBaseQuery';

const api = createApi({
	reducerPath: 'content-allocation-api',
	tagTypes: ['refreshers'],
	baseQuery: buildBaseQuery('/api/v1/content-allocation'),
	endpoints: (builder) => ({
		/**
		 * @see file://./../../../../api/src/content-allocation/exports/controllers/assigned-content.controller.ts
		 */
		getAssignedContentAllocation: builder.query<
			SchemaAssignedContentResponseDto[],
			string
		>({
			query: (userId: string) => `assigned/${userId}`,
		}),
		/**
		 * @see file://./../../../../api/src/content-allocation/exports/controllers/assigned-content.controller.ts
		 */
		getCompletedAssignedContentAllocation: builder.query<
			SchemaAssignedContentResponseDto[],
			string
		>({
			query: (userId: string) => `assigned/${userId}/completed`,
		}),
		/**
		 * @see file://./../../../../api/src/content-allocation/exports/controllers/assigned-content.controller.ts
		 */
		assignContent: builder.mutation<void, SchemaCreateAssignContentRequestDto>({
			query: ({ userIds, reference }) => ({
				url: `assigned`,
				method: 'POST',
				body: {
					userIds,
					reference,
				},
			}),
		}),
		/**
		 * @see file://./../../../../api/src/content-allocation/exports/controllers/assigned-content.controller.ts
		 */
		skipAssignment: builder.mutation<void, string>({
			invalidatesTags: ['refreshers'],
			query: (id: string) => ({
				url: `assigned/skip/${id}`,
				method: 'PATCH',
			}),
		}),
	}),
});

export const {
	useGetAssignedContentAllocationQuery,
	useGetCompletedAssignedContentAllocationQuery,
	useSkipAssignmentMutation,
	useAssignContentMutation,
} = api;
export default api;
