import { SchemaJourneyResponseDto } from '@jam/api-sdk';
import { useGetJourneyQuery } from '../../../redux/api/cmsApi';

export const useGetJourney = (
	journeyId: string | undefined
): SchemaJourneyResponseDto | null | undefined => {
	const { data: journey } = useGetJourneyQuery(journeyId || '', {
		skip: !journeyId,
	});

	if (!journeyId) {
		return undefined;
	}

	if (!journey) {
		return null;
	}

	return journey;
};
