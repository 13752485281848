import { SchemaSessionResponseDto } from '@jam/api-sdk';
import { useGetSessionsByUserIdQuery } from '../../../redux/api/sessionApi';

export type UserLatestSessions = SchemaSessionResponseDto;

export const useGetUserLatestSessions = (
	userId: string | undefined,
	limit = 3
): UserLatestSessions[] | undefined | null => {
	const { data, isLoading } = useGetSessionsByUserIdQuery(userId ?? '', {
		skip: !userId,
	});

	if (isLoading) return undefined;
	if (!data?.length) return null;

	const sortedSessions = data
		.slice()
		.sort((a, b) => {
			return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
		})
		.slice(0, limit);

	return sortedSessions.map((x) => {
		return {
			...x,
		};
	});
};
