import { Box, Flex, Skeleton, SkeletonText, Text } from '@chakra-ui/react';
import {
	CreateAssignContentRequestDTOReferenceType,
	SchemaAssignedContentResponseDto,
	SchemaStoryResponseDto,
} from '@jam/api-sdk';
import { StoryDetail } from './StoryDetail';
import { TrackAssignment } from './TrackAssignment';

const LoadingState = () => {
	return (
		<Flex
			role="placeholder"
			bg="#FCFCFC"
			border={'1px'}
			borderColor={'#D9D9D9'}
			borderRadius={'24px'}
			p="5"
			w="100%"
			gap={'10'}
			direction={'column'}
		>
			<Box w="100%">
				<SkeletonText noOfLines={4} spacing="5" />
			</Box>
			<Flex w="100%" direction={'column'} gap="6">
				{Array(4)
					.fill(null)
					.map((_, index) => (
						<Flex key={index} gap="6" alignContent={'center'}>
							<Skeleton borderRadius={'8px'} h="90px" w="120px" size="10" />
							<SkeletonText w={'full'} noOfLines={4} spacing={'4'} />
						</Flex>
					))}
			</Flex>
		</Flex>
	);
};

type AssignmentsListProps = {
	title: string;
	contentAllocations?: SchemaAssignedContentResponseDto[];
	activeStory?: SchemaStoryResponseDto;
	hideAction?: boolean;
};

export const AssignmentsList = ({
	title,
	contentAllocations,
	activeStory,
	hideAction,
}: AssignmentsListProps) => {
	if (!contentAllocations) {
		return <LoadingState />;
	}

	const list = contentAllocations.map((contentAllocation) => {
		return contentAllocation.reference.type ===
			CreateAssignContentRequestDTOReferenceType.journey ? (
			<TrackAssignment
				hideAction={hideAction}
				key={contentAllocation.id}
				activeStoryId={activeStory?.id}
				journeyId={contentAllocation.reference.id}
				completedStoryIds={contentAllocation.completedStoryIds}
			/>
		) : (
			<StoryDetail
				hideAction={hideAction}
				key={contentAllocation.id}
				storyId={contentAllocation.reference.id}
			/>
		);
	});

	return (
		<Box>
			<Flex mb="6" alignItems={'center'} gap="2">
				<Text
					w="fit-content"
					fontSize="2xl"
					color={'#757575'}
					fontWeight="semibold"
				>
					{title}
				</Text>
			</Flex>
			<Flex mb="6" direction={'column'} gap="6">
				{list}
			</Flex>
		</Box>
	);
};
