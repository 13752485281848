import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../store';

export function buildBaseQuery(path: string) {
	return fetchBaseQuery({
		baseUrl: `${
			process.env
				.REACT_APP_SALES_ENABLEMENT_PLATFORM_NEST_API_BASE_URL as string
		}${path}`,
		prepareHeaders: (headers, { getState }) => {
			const token = (getState() as RootState).applicationState.accessToken;

			if (token) {
				headers.set('Authorization', `Bearer ${token}`);
			}

			return headers;
		},
	});
}
