import {
	AlertDialog,
	AlertDialogOverlay,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogBody,
	AlertDialogFooter,
	Button,
} from '@chakra-ui/react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

type CallTooShortDialogProps = {
	isOpen: boolean;
	onCancelFeedback: () => void;
	onGenerateFeedback: () => void;
};

const CallTooShortDialog = ({
	isOpen,
	onCancelFeedback,
	onGenerateFeedback,
}: CallTooShortDialogProps) => {
	const { t } = useTranslation('call');
	const cancelRef = useRef<HTMLButtonElement | null>(null);
	return (
		<AlertDialog
			closeOnOverlayClick={false}
			size={'xl'}
			isOpen={isOpen}
			leastDestructiveRef={cancelRef}
			onClose={onCancelFeedback}
		>
			<AlertDialogOverlay>
				<AlertDialogContent>
					<AlertDialogHeader fontSize="lg" fontWeight="bold">
						{t('callTooShortDialog.title')}
					</AlertDialogHeader>

					<AlertDialogBody>{t('callTooShortDialog.text')}</AlertDialogBody>

					<AlertDialogFooter>
						<Button
							id="generate-feedback-call-too-short-dialog-button"
							variant={'outline'}
							colorScheme="green"
							ref={cancelRef}
							onClick={onGenerateFeedback}
						>
							{t('callTooShortDialog.generate_button')}
						</Button>
						<Button
							id="cancel-call-too-short-dialog-button"
							colorScheme="red"
							variant={'outline'}
							onClick={onCancelFeedback}
							ml={3}
						>
							{t('callTooShortDialog.cancel_button')}
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialog>
	);
};

export default CallTooShortDialog;
