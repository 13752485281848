import { Divider, Flex, Text } from '@chakra-ui/react';

type MenuDividerProps = {
	text: string;
};

export const MenuDivider = ({ text }: MenuDividerProps) => {
	return (
		<Flex height={'14px'} my="2" px="3" gap={1} alignItems={'center'}>
			{text && (
				<Text color="#757575" fontSize="12px">
					{text}
				</Text>
			)}
			<Divider color="#757575" />
		</Flex>
	);
};
