import { useState } from 'react';
import { useGetInvitedUsersQuery } from '../../../../../redux/api/crmApi';

export const useGetPendingInvitesData = ({ orgId }: { orgId: string }) => {
	const { data, isLoading } = useGetInvitedUsersQuery({
		orgId,
	});

	const [search, setSearch] = useState('');
	const customFilter = [{ id: 'email', value: search }];
	const userData = data ?? [];

	return {
		isLoading,
		userData,
		customFilter,
		search,
		setSearch,
	};
};
