import { Tooltip } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RectangleProps } from 'recharts';

interface WeeklyUsersChartBarShapeProps extends RectangleProps {
	dataKey: string;
	payload: {
		active: number;
		inactive: number;
	};
}

export const WeeklyUsersChartBarShape: React.FC<
	WeeklyUsersChartBarShapeProps
> = (props) => {
	const { x = 0, y = 0, width = 0, height = 0, dataKey, fill, payload } = props;
	const { t } = useTranslation('dashboard', {
		keyPrefix: 'team_activity_section',
	});

	// Gap size in pixels
	const gapSize = 2;

	// Adjust y and height based on whether it's the top or bottom bar
	let adjustedY = y;
	let adjustedHeight = height;

	if (dataKey === 'active') {
		// Top bar
		adjustedY = y + gapSize; // Move down by gapSize
		adjustedHeight = height - gapSize; // Decrease height by gapSize
	} else if (dataKey === 'inactive') {
		// Bottom bar
		adjustedHeight = height - gapSize; // Decrease height by gapSize
	}

	// Ensure adjustedHeight is not negative
	if (adjustedHeight < 0) {
		adjustedHeight = 0;
	}

	return (
		<Tooltip
			placement="top"
			label={t('active_users_chart', {
				active: payload.active,
				inactive: payload.inactive,
			})}
		>
			<rect
				x={x}
				y={adjustedY}
				width={width}
				height={adjustedHeight}
				rx={4}
				fill={fill}
			/>
		</Tooltip>
	);
};
