import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CompletionStats } from '../../../../../common/components/CompletionStats';
import { useGetDetailedUserCompletionsQuery } from '../../../../../redux/api/dashboardApi';

export const AllTimeCompletions = () => {
	const { t } = useTranslation('dashboard');
	const { userId } = useParams<{ userId: string }>();
	const { data, isLoading } = useGetDetailedUserCompletionsQuery(
		userId as string,
		{
			skip: !userId,
		}
	);

	return (
		<CompletionStats
			title={t('dashboard:detailed_user_view:learning_progress')}
			data={data}
			isLoading={isLoading}
		/>
	);
};
