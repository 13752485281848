import { useActiveStory } from '../../home/hooks/useActiveStory';
import { useGetNextStories } from '../../home/hooks/useGetNextStories';
import { useReferer } from '../../home/hooks/useReferer';

type NextStepAction = {
	action: 'nextStory' | 'back';
	nextStoryUrl: string;
};

const getBackNextUrl = (ref: string | null) => {
	if (ref === 'home') {
		return '/';
	}
	if (ref === 'gym') {
		return '/gym';
	}
	if (ref === 'history') {
		return '/history';
	}
	return `/users/history`;
};

export const useNextStep = (): NextStepAction | undefined => {
	const { upcomingStories } = useGetNextStories();
	const { activeStory } = useActiveStory();
	const { ref } = useReferer();
	const backNextUrl = getBackNextUrl(ref);

	if (!upcomingStories) {
		return undefined;
	}

	const activeStoryIndex = upcomingStories.findIndex(
		(story) => story.id === activeStory
	);

	if (activeStoryIndex === -1) {
		return {
			action: 'back',
			nextStoryUrl: backNextUrl,
		};
	}

	const nextStory = upcomingStories[activeStoryIndex + 1];

	if (nextStory) {
		return {
			action: 'nextStory',
			nextStoryUrl: `call?storyId=${nextStory.id}&ref=${ref ?? ''}&journey=${
				nextStory?.trackTitle ?? ''
			}`,
		};
	}

	return {
		action: 'back',
		nextStoryUrl: backNextUrl,
	};
};
