import { StringParam, useQueryParam, withDefault } from 'use-query-params';

export const useLearnerId = () => {
	const [learnerId] = useQueryParam<string | null>(
		'learnerId',
		withDefault(StringParam, null)
	);

	return { learnerId };
};
