import React from 'react';
import { Link, useRouteError } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Text } from '@chakra-ui/react';
import { CenteredMessagePageWrapper } from '../common/components/CenteredMessagePageWrapper';

const ErrorPage: React.FC = () => {
	const error = useRouteError();
	Sentry.captureException(error);

	return (
		<CenteredMessagePageWrapper>
			<Text sx={{ fontSize: 18, lineHeight: '28px', fontWeight: 700 }}>
				System error
			</Text>
			<Text variant="subtitle1">
				Please try reloading the page or restarting the app.
			</Text>
			<Text variant="subtitle1">
				Sorry for the inconvenience, but something went wrong! If this keeps
				happening,{' '}
				<Link
					to="#"
					onClick={(e) => {
						e.preventDefault();
						window.location.href = 'mailto:support@support.com';
						// TODO: add support email + phone
					}}
				>
					contact customer service
				</Link>
				.
			</Text>
			<Text variant="subtitle1">Thank you!</Text>
		</CenteredMessagePageWrapper>
	);
};

export default ErrorPage;
