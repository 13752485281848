import { Button, Flex, useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Plus } from '@phosphor-icons/react';
import { AddUserModal } from '../../../../settings/components/AddUserModal';

export const InviteUsers = () => {
	const { t } = useTranslation(['team', 'settings']);
	const { isOpen, onOpen, onClose } = useDisclosure();

	return (
		<Flex gap="5">
			<Button
				id="add-user-button"
				leftIcon={<Plus size={15} />}
				borderRadius={'full'}
				type="submit"
				px="6"
				bgColor={'brand.500'}
				size="md"
				onClick={() => {
					onOpen();
				}}
			>
				{t('settings:add-user')}
			</Button>
			<AddUserModal isOpen={isOpen} onClose={onClose} />
		</Flex>
	);
};
