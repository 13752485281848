import { createApi } from '@reduxjs/toolkit/query/react';
import { buildBaseQuery } from './buildBaseQuery';

export enum RequestTypeId {
	BUG_REPORT = '6',
	CONTENT_EDIT = '9',
}

const SERVICE_DESK_ID = '1';

const api = createApi({
	reducerPath: 'common-api',
	baseQuery: buildBaseQuery('/api/v1'),
	endpoints: (builder) => ({
		/**
		 * @see file://./../../../../api/src/common/exports/controllers/issue-report.controller.ts
		 */
		reportIssue: builder.mutation<
			void,
			{
				summary: string;
				description: string;
				requestTypeId: string;
			}
		>({
			query: ({ summary, description, requestTypeId }) => ({
				url: 'issue-report',
				method: 'POST',
				body: {
					serviceDeskId: SERVICE_DESK_ID,
					requestTypeId,
					requestFieldValues: {
						summary,
						description,
					},
				},
			}),
		}),
	}),
});

export const { useReportIssueMutation } = api;
export default api;
