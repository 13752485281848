import { z, ZodType } from 'zod';

export type UserFeedback = {
	rating: string;
	feedback: string;
};

export const UserFeedbackSchema: ZodType<UserFeedback> = z.object({
	rating: z.string(),
	feedback: z.string(),
});

export type NegativeFeedbackPerItem = {
	reasons: string[];
	feedback: string;
};

export const NegativeFeedbackPerItemSchema: ZodType<NegativeFeedbackPerItem> =
	z.object({
		reasons: z.array(z.string()),
		feedback: z.string(),
	});
