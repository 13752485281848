import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { AppLayout } from '../../common/components/AppLayout';
import { BreadCrumbs } from '../../common/components/BreadCrumbs';
import { JourneyDetail } from '../../features/manager-journeys/components/JourneyDetail';
import { useGetJourney } from '../../features/manager-journeys/hooks/useGetJourney';

export const ManagerJourneyDetailsPage = () => {
	const { t } = useTranslation('common');
	const { journeyId } = useParams();
	const journey = useGetJourney(journeyId);

	if (!journey) {
		return null;
	}

	return (
		<AppLayout>
			<BreadCrumbs
				withUserWidget
				backLink="/journeys"
				items={[
					{
						title: t('tracks'),
						link: '/journeys',
					},
					{
						title: journey.title,
						link: `/journey/${journey.id}`,
					},
				]}
			/>
			<JourneyDetail />
		</AppLayout>
	);
};
