import React from 'react';
import { Flex, Stack } from '@chakra-ui/react';

type CenteredMessagePageWrapperProps = {
	children: React.ReactNode;
};

// Can be used in the future for more Responsiveness-related complex behavior
export const CenteredMessagePageWrapper = ({
	children,
}: CenteredMessagePageWrapperProps) => {
	return (
		<Flex
			direction="column"
			justifyContent="center"
			alignItems="center"
			height="100vh"
			p={4}
		>
			<Stack
				maxWidth="600px"
				direction="column"
				spacing={2}
				sx={{ textAlign: 'center' }}
				justifyContent="center"
				alignItems="center"
			>
				{children}
			</Stack>
		</Flex>
	);
};
