import { createIcon } from '@chakra-ui/react';

export const XpIcon = createIcon({
	displayName: 'XpIcon',
	viewBox: '0 0 16 16',
	path: [
		<path
			key={0}
			d="M14 10.6667V5.33335C13.9998 5.09953 13.938 4.86989 13.821 4.66746C13.704 4.46503 13.5358 4.29692 13.3333 4.18002L8.66667 1.51335C8.46397 1.39633 8.23405 1.33472 8 1.33472C7.76595 1.33472 7.53603 1.39633 7.33333 1.51335L2.66667 4.18002C2.46418 4.29692 2.29599 4.46503 2.17897 4.66746C2.06196 4.86989 2.00024 5.09953 2 5.33335V10.6667C2.00024 10.9005 2.06196 11.1301 2.17897 11.3326C2.29599 11.535 2.46418 11.7031 2.66667 11.82L7.33333 14.4867C7.53603 14.6037 7.76595 14.6653 8 14.6653C8.23405 14.6653 8.46397 14.6037 8.66667 14.4867L13.3333 11.82C13.5358 11.7031 13.704 11.535 13.821 11.3326C13.938 11.1301 13.9998 10.9005 14 10.6667Z"
			fill="#EBF934"
			stroke="#0B1215"
			strokeWidth="1.25"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>,
		<path
			key={1}
			d="M5.63117 6.36364L6.36448 7.60298H6.39289L7.12975 6.36364H7.998L6.88827 8.18182L8.02286 10H7.13863L6.39289 8.75888H6.36448L5.61874 10H4.73806L5.8762 8.18182L4.75937 6.36364H5.63117ZM8.48451 10V6.36364H9.91917C10.195 6.36364 10.4299 6.41631 10.6241 6.52166C10.8182 6.62583 10.9662 6.77083 11.068 6.95668C11.1709 7.14134 11.2224 7.3544 11.2224 7.59588C11.2224 7.83736 11.1704 8.05043 11.0662 8.23509C10.962 8.41974 10.8111 8.56357 10.6134 8.66655C10.4169 8.76953 10.179 8.82102 9.89964 8.82102H8.98522V8.2049H9.77535C9.92331 8.2049 10.0452 8.17945 10.1411 8.12855C10.2382 8.07647 10.3104 8.00485 10.3577 7.91371C10.4063 7.82138 10.4305 7.71544 10.4305 7.59588C10.4305 7.47514 10.4063 7.36979 10.3577 7.27983C10.3104 7.18868 10.2382 7.11825 10.1411 7.06854C10.044 7.01764 9.92094 6.99219 9.7718 6.99219H9.25333V10H8.48451Z"
			fill="#0B1215"
		/>,
	],
});
