import { useAppSelector } from '../../../redux/hooks';
import {
	selectBotIsSpeaking,
	selectCurrentCallStatus,
} from '../../../redux/slice';
import { useMicrophonePermission } from '../hooks/useMicrophonePermission';
import { AnimatedCallButton } from './AnimatedCallButton';
import { MicrophonePermission } from './MicrophonePermission';

type CallButtonProps = {
	firstTime: boolean;
	isPrimary: boolean;
	onCallerClick: () => void;
};

export const CallButton = ({
	firstTime,
	isPrimary,
	onCallerClick,
}: CallButtonProps) => {
	const { permissionState, requestMicrophone } = useMicrophonePermission();
	const callStatus = useAppSelector(selectCurrentCallStatus);
	const botIsSpeaking = useAppSelector(selectBotIsSpeaking);

	const onClickHandler = () => {
		if (permissionState === 'granted') {
			onCallerClick();
			return;
		}

		requestMicrophone();
	};

	if (callStatus === null) {
		return null;
	}

	return (
		<>
			<MicrophonePermission />
			<AnimatedCallButton
				firstTime={firstTime}
				isPrimary={isPrimary}
				callStatus={callStatus}
				botIsSpeaking={botIsSpeaking}
				onCallerClick={onClickHandler}
			/>
		</>
	);
};
