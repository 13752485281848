import { Flex } from '@chakra-ui/react';
import { useAuthInfo } from '@propelauth/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppLayout } from '../common/components/AppLayout';
import { NextStory } from '../features/home/components/NextStory';
import { Salutation } from '../features/home/components/Salutation';
import { CompletedLearnings } from '../features/user-history/components/CompletedLearnings';
import { HistoryTable } from '../features/user-history/components/HistoryTable';

export const UserHistoryPage = () => {
	const { t } = useTranslation('history');
	const { user } = useAuthInfo();
	const navigate = useNavigate();

	return (
		<AppLayout>
			<Salutation text={t('history')} />
			<Flex mt="10" direction={{ base: 'column' }} gap="5">
				<CompletedLearnings userId={user?.userId} />
				<NextStory page="history" />
				<HistoryTable
					userId={user?.userId}
					onRowClick={(item) => {
						navigate(
							`/call?storyId=${item.storyId}&sessionId=${item.sessionId}&ref=history&activeCallTabIndex=2`
						);
					}}
				/>
			</Flex>
		</AppLayout>
	);
};
