import {
	Box,
	Button,
	Flex,
	Image,
	Skeleton,
	SkeletonText,
	Stack,
	Text,
} from '@chakra-ui/react';
import {
	AssignedContentResponseDTOType,
	SessionResponseDTOAnalysisItemsSolved,
} from '@jam/api-sdk';
import { CaretRight, Clock } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Chip } from '../../../common/components/Chip';
import { useStoryStatus } from '../../../common/hooks/useStoryStatus';
import { useGetFirstNotCompletedStory } from '../../../common/utils/getfirstNotCompletedStory';
import {
	useGetStoryLastSessionQuery,
	useGetStorySessionsQuery,
} from '../../../redux/api/sessionApi';
import { useDifficultyIcon } from '../hooks/useDifficultyIcon';

type NextStoryProps = {
	page: string;
};

const LoadingState = () => {
	return (
		<Flex role="placeholder" direction={'column'} gap="3">
			<Box maxW="300px">
				<SkeletonText noOfLines={1} height={'30px'} />
			</Box>
			<Flex gap="4" alignItems={'center'}>
				<Skeleton borderRadius={'8px'} h="175px" w="175px" />
				<SkeletonText w={'full'} noOfLines={5} spacing={'5'} />
			</Flex>
		</Flex>
	);
};

export const NextStory = ({ page }: NextStoryProps) => {
	const { t } = useTranslation(['home', 'story']);
	const navigate: NavigateFunction = useNavigate();

	const { activeStory, isLoading: isLoadingActiveStory } =
		useGetFirstNotCompletedStory();
	const status = useStoryStatus(activeStory?.id ?? '');

	const { data: lastSession } = useGetStoryLastSessionQuery(
		activeStory?.id ?? '',
		{
			skip: !activeStory,
		}
	);

	const { data: storySessions } = useGetStorySessionsQuery(
		activeStory?.id ?? '',
		{
			skip: !activeStory,
		}
	);

	const difficultyIcon = useDifficultyIcon(activeStory?.difficulty);

	if (isLoadingActiveStory) {
		return <LoadingState />;
	}

	if (activeStory === null) {
		return null;
	}

	let buttonText = '';
	if (status === 'IN_PROGRESS') {
		buttonText = t('resume');
	} else {
		buttonText = t('start');
	}

	const onCall = () =>
		navigate(
			`/call?storyId=${activeStory.id}&ref=${page}&journey=${activeStory.trackTitle ?? ''}`
		);

	const analysis = lastSession?.analysis;
	const completedItems =
		analysis?.items.filter(
			(item) => item.solved === SessionResponseDTOAnalysisItemsSolved.solved
		).length || 0;
	const allItems = activeStory.items_conversation_feedback.length;

	return (
		<Box>
			<Flex mb="3" alignItems={'center'} gap="2">
				<Text w="fit-content" fontSize="20px" fontWeight="500">
					{t('your_next_mission')}
				</Text>
			</Flex>
			<Box
				borderRadius={'32px'}
				bg="#FAFAFA"
				border={'1px'}
				borderColor={'#D9D9D9'}
			>
				<Flex justifyContent={'space-between'}>
					<Flex gap="3">
						<Box w="175px" h="100%" flexShrink={0}>
							<Image
								w="175px"
								h="100%"
								objectFit="cover"
								borderLeftRadius={'12px'}
								src={activeStory.persona.avatar_file?.url}
							/>
						</Box>
						<Flex direction={'column'} justifyContent={'space-between'} my="4">
							<Text fontSize={'24px'} fontWeight={'medium'}>
								{activeStory.title}
							</Text>
							<Text
								fontSize={'16px'}
								color="#757575"
								cursor={'pointer'}
								onClick={() => navigate(`/gym`)}
							>
								{t('story:track_title', { track: activeStory.trackTitle })}
							</Text>
							<Text
								fontSize={'16px'}
								color="#757575"
								cursor={'pointer'}
								onClick={() => navigate(`/gym`)}
							>
								{activeStory.type === AssignedContentResponseDTOType.refresher
									? t('history:from-refresher')
									: t('history:from-assignment')}
							</Text>
							<Stack alignItems={'center'} direction="row" spacing={2} mt="2">
								{activeStory.duration && (
									<Chip
										px="1"
										py="0"
										bg={'#F5F5F5'}
										border={0}
										borderRadius={'2px'}
										gap={'4px'}
										fontSize={'14px'}
										color="#757575"
										icon={<Clock size={14} color="#757575" />}
										text={t('story:duration_value', {
											duration: activeStory.duration,
										})}
										aria-label="Story duration"
									/>
								)}

								{activeStory.difficulty && (
									<Chip
										px="1"
										py="0"
										bg={'#F5F5F5'}
										borderRadius={'2px'}
										fontSize={'14px'}
										gap={'4px'}
										color="#757575"
										border={0}
										icon={
											difficultyIcon ? (
												<Image src={difficultyIcon} />
											) : undefined
										}
										text={t(`story:${activeStory.difficulty}`)}
										aria-label="Story difficulty"
									/>
								)}
							</Stack>
						</Flex>
					</Flex>
					<Flex
						mr="5"
						direction={'column'}
						alignItems={'flex-end'}
						justifyContent={'space-between'}
						flexShrink={0}
						my="4"
					>
						<Button
							id="next-story-start-button"
							borderRadius={'32px'}
							textColor={'white'}
							bg={'#4241E4'}
							gap={'4px'}
							_hover={{ bg: '#4241E4' }}
							onClick={onCall}
						>
							<Text>{buttonText}</Text>
							<CaretRight size="12px" />
						</Button>
						<Stack
							alignItems={'center'}
							direction="row"
							spacing={2}
							mt="2"
							divider={<Text>·</Text>}
						>
							{storySessions && (
								<Chip
									px="1"
									py="0"
									bg={'transparent'}
									borderRadius={'2px'}
									fontSize={'14px'}
									gap={'2px'}
									color="#757575"
									border={0}
									icon={<Image src="/icons/chronometer.svg" w="16px" />}
									text={t('story:rounds_played', {
										count: storySessions.length,
									})}
								/>
							)}
							{allItems && (
								<Chip
									px="1"
									py="0"
									bg={'transparent'}
									borderRadius={'2px'}
									fontSize={'14px'}
									gap={'2px'}
									color="#4241E4"
									border={0}
									text={t('story:tasks_solved', {
										completedItems,
										allItems,
									})}
								/>
							)}
						</Stack>
					</Flex>
				</Flex>
			</Box>
		</Box>
	);
};
