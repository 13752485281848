import React from 'react';
import { useTranslation } from 'react-i18next';

interface WeeklyCompletedRoundsChartProps {
	completedSessions: Record<string, number>;
}
import { Tooltip } from '@chakra-ui/react';
import { BarProps, RectangleProps } from 'recharts';
import DashboardChart from '../common/charts/DashboardChart';
import { ActiveShape } from 'recharts/types/util/types';

interface CompletedRoundsBarShapeProps extends RectangleProps {
	payload: {
		value: number;
	};
}

export const CompletedRoundsBarShape: React.FC<CompletedRoundsBarShapeProps> = (
	props
) => {
	const { x = 0, y = 0, width = 0, height = 0, fill, payload } = props;
	const { t } = useTranslation();

	return (
		<Tooltip
			placement="top"
			label={t('call:rounds_played', { count: payload.value })}
		>
			<rect
				x={x}
				y={y}
				width={width}
				height={height}
				rx={4}
				fill={fill}
				strokeOpacity={0.3}
				strokeWidth={2}
				strokeDasharray="4 4"
			/>
		</Tooltip>
	);
};

export const WeeklyCompletedRoundsChart: React.FC<
	WeeklyCompletedRoundsChartProps
> = ({ completedSessions }) => {
	const { t } = useTranslation('dashboard');

	const keys = Object.keys(completedSessions).sort();
	const regex = /W(\d+)/;

	const data = keys.map((key) => ({
		name: t('team_activity_section.week_number', {
			weekNumber: key.match(regex)?.[1],
		}),
		value: completedSessions[key],
	}));

	const bars = [
		{
			dataKey: 'value',
			name: t('dashboard:team_activity_section.rounds_completed'),
			fill: '#027864',
			shape: CompletedRoundsBarShape as
				| ActiveShape<BarProps, SVGPathElement>
				| undefined,
		},
	];

	return (
		<DashboardChart
			title={t('team_activity_section.rounds_completed')}
			hint={t('team_activity_section.role_play_sessions')}
			data={data}
			xAxisKey="name"
			bars={bars}
		/>
	);
};
