import { theme } from '@chakra-ui/pro-theme';
import { extendTheme } from '@chakra-ui/react';
const proTheme = extendTheme(theme);
export const customTheme = extendTheme(
	{
		fonts: {
			heading: `'Inter', sans-serif`,
			body: `'Inter', sans-serif`,
		},
		colors: {
			brand: {
				500: '#4241E4',
			},
		},
		components: {
			Progress: {
				baseStyle: {
					borderRadius: 'full',
					borderColor: '#757575',
					borderWidth: '1px',
					filledTrack: {
						bg: '#4241E4',
						borderRadius: 'full',
					},
				},
				variants: {
					yellow: {
						filledTrack: {
							bg: '#EBF934',
							borderRadius: 'full',
						},
					},
				},
			},
		},
	},
	proTheme
);
