import {
	Box,
	Button,
	Flex,
	IconButton,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
} from '@chakra-ui/react';
import { CaretLeft, CaretRight, CaretUpDown } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import React from 'react';

interface Props {
	page: number;
	setPage: React.Dispatch<React.SetStateAction<number>>;
	numberOfPages: number;
	rowsPerPage: number;
	setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
}
export const Pagination: React.FC<Props> = ({
	page,
	setPage,
	numberOfPages,
	rowsPerPage,
	setRowsPerPage,
}) => {
	const { t } = useTranslation('common');
	return (
		<Box my="4">
			<Flex gap="5" ml="auto" w="fit-content">
				<Flex gap={2} alignItems={'center'}>
					<Text fontWeight={'medium'} fontSize={'12px'}>
						{t('rows_per_page')}
					</Text>
					<Menu>
						<MenuButton
							bg="transparent"
							border={'1px'}
							borderColor={'black'}
							size={'xs'}
							color={'black'}
							as={Button}
							rightIcon={<CaretUpDown color="black" />}
						>
							{rowsPerPage}
						</MenuButton>
						<MenuList px="5px" w="50px" borderRadius={'24px'}>
							{[10, 20, 30, 40, 50].map((value) => (
								<MenuItem
									borderRadius={'12px'}
									key={value}
									onClick={() => {
										setRowsPerPage(value);
										setPage(1);
									}}
								>
									{value}
								</MenuItem>
							))}
						</MenuList>
					</Menu>
				</Flex>
				<Flex alignItems={'center'}>
					<Text fontWeight={'medium'} fontSize={'12px'}>
						{t('page', { page, pages: numberOfPages })}
					</Text>
					<IconButton
						bg="transparent"
						border={'1px'}
						borderColor={'black'}
						icon={<CaretLeft color="black" />}
						size="xs"
						ml="2"
						aria-label={''}
						onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
					/>
					<IconButton
						bg="transparent"
						border={'1px'}
						borderColor={'black'}
						icon={<CaretRight color="black" />}
						size="xs"
						ml="2"
						aria-label={''}
						onClick={() => setPage((prev) => Math.min(prev + 1, numberOfPages))}
					/>
				</Flex>
			</Flex>
		</Box>
	);
};
