import {
	FilteringOptions,
	FilteringOptionsValue,
	PreparedFilteringOptions,
} from '../types/FilteringTypes';
import { useCallback, useMemo, useState } from 'react';

export interface UseFiltersProps {
	filters: FilteringOptions[];
}

interface State {
	values: Record<string, FilteringOptionsValue>;
}

const emptyState: State = {
	values: {},
};

/**
 * Custom hook for filtering items with generic type support
 * @param filters - The list of filtering options
 * @param filterItems - Function to filter items based on the filter values
 * @returns A tuple containing prepared filters and the filtered list of items
 */
export const useFilters = ({
	filters,
}: UseFiltersProps): PreparedFilteringOptions[] => {
	const [state, setState] = useState<State>(emptyState);

	const onChangeFilter = useCallback(
		(filterId: string, value: string) => {
			setState((prevState) => {
				return {
					...prevState,
					values: {
						...prevState.values,
						[filterId]: value,
					},
				};
			});
		},
		[setState]
	);

	const prepareFilters = useCallback(
		(toPrepareFilters: FilteringOptions[], innerState: State) => {
			return toPrepareFilters.map((filter) => {
				return {
					value: innerState.values[filter.id] || '',
					placeholder: filter.placeholder,
					options: filter.options,
					onChange: (value: string) => {
						onChangeFilter(filter.id, value);
					},
					...filter,
				};
			});
		},
		[onChangeFilter]
	);

	return useMemo(() => {
		return prepareFilters(filters, state);
	}, [filters, state]);
};
