import {
	Accordion,
	AccordionButton,
	AccordionItem,
	AccordionPanel,
	Box,
	Divider,
	Flex,
	Image,
	Text,
} from '@chakra-ui/react';
import { SchemaStoryResponseDto } from '@jam/api-sdk';
import { Circle, Minus, Plus, Target, User } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
type HintsAndGoalsProps = {
	story: SchemaStoryResponseDto;
};

export const HintsAndGoals = ({ story }: HintsAndGoalsProps) => {
	const { t } = useTranslation('call');
	const goal = story.goal;
	if (!goal) {
		return null;
	}
	const scenario = story.description;
	const personaBase = story.persona;

	return (
		<Flex direction={'column'} gap="4">
			<Flex
				border={'1px'}
				borderColor={'#D9D9D9'}
				px="8"
				py="6"
				gap="2"
				borderRadius={'24px'}
				alignItems={'center'}
				bg={'#FAFAFA'}
			>
				<Box border={'1px'} p="2" borderRadius={'24px'} borderColor={'#D9D9D9'}>
					<Target size={20} />
				</Box>
				<Text fontSize={'16px'}>{goal}</Text>
			</Flex>
			<Accordion allowMultiple defaultIndex={[0]}>
				<AccordionItem border={0}>
					{({ isExpanded }) => (
						<Box
							borderRadius={'24px'}
							border={'1px'}
							borderColor={'#D9D9D9'}
							px="4"
							py="6"
						>
							<AccordionButton _hover={{ background: 'transparent' }} w="100%">
								<Flex
									alignItems={'center'}
									w="100%"
									justifyContent={'space-between'}
								>
									<Flex gap="2" alignItems={'center'}>
										<Box
											border={'1px'}
											p="2"
											borderRadius={'24px'}
											borderColor={'#D9D9D9'}
										>
											<Image src="/icons/scenario-list.svg" />
										</Box>
										<Text fontSize={'16px'}>{t('scenario')}</Text>
									</Flex>

									{isExpanded ? (
										<Minus weight="bold" size={20} />
									) : (
										<Plus weight="bold" size={20} />
									)}
								</Flex>
							</AccordionButton>
							<AccordionPanel>
								<Divider color={'#757575'} mt="2" mb="4" />
								<Flex direction={'column'} gap="2" mt="3">
									{scenario
										.trim()
										.split('.')
										.filter((x) => !!x)
										.map((sentence) => (
											<Flex alignItems={'center'} gap="2" key={sentence}>
												<Box flexShrink={0}>
													<Circle color="#757575" size={5} weight="fill" />
												</Box>
												<Text color="#757575" fontSize="14px">
													{sentence}
												</Text>
											</Flex>
										))}
								</Flex>
							</AccordionPanel>
						</Box>
					)}
				</AccordionItem>
				{personaBase.description && (
					<AccordionItem mt="10" border={0}>
						{({ isExpanded }) => (
							<Box
								borderRadius={'24px'}
								border={'1px'}
								borderColor={'#D9D9D9'}
								px="4"
								py="6"
							>
								<AccordionButton
									_hover={{ background: 'transparent' }}
									w="100%"
								>
									<Flex
										alignItems={'center'}
										w="100%"
										justifyContent={'space-between'}
									>
										<Flex gap="2" alignItems={'center'}>
											<Box
												border={'1px'}
												p="2"
												borderRadius={'24px'}
												borderColor={'#D9D9D9'}
											>
												<User size={20} />
											</Box>
											<Text fontSize={'16px'}>
												{t('about_person', { name: personaBase.name })}
											</Text>
										</Flex>
										{isExpanded ? (
											<Minus weight="bold" size={20} />
										) : (
											<Plus weight="bold" size={20} />
										)}
									</Flex>
								</AccordionButton>
								<AccordionPanel>
									<Text
										mt="5"
										color={'#757575'}
										fontSize={'14px'}
										lineHeight={'24px'}
									>
										{personaBase.description}
									</Text>
								</AccordionPanel>
							</Box>
						)}
					</AccordionItem>
				)}
			</Accordion>
		</Flex>
	);
};
