import React from 'react';
import {
	Flex,
	Box,
	Select as ChakraSelect,
	SelectProps,
} from '@chakra-ui/react';

export interface AppSelectProps extends SelectProps {
	prefixIcon?: React.ReactNode;
}

export const Select = ({ prefixIcon, children, ...props }: AppSelectProps) => {
	return (
		<Flex
			alignItems="center"
			h="32px"
			p="7px 12px"
			borderRadius="8px"
			bg="#F5F5F5"
			justifyContent="space-between"
			width={props.width}
			gap={1}
		>
			{prefixIcon && (
				<Box height="1rem" width="1rem">
					{prefixIcon}
				</Box>
			)}
			<ChakraSelect
				width={'100%'}
				variant="unstyled"
				flexGrow={1}
				flex={1}
				h="100%"
				gap={1}
				placeholder={props.placeholder}
				fontFamily="Inter"
				fontSize="14px"
				fontStyle="normal"
				fontWeight="500"
				lineHeight="110%"
				{...props}
			>
				{children}
			</ChakraSelect>
		</Flex>
	);
};
