import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';

const AMPLITUDE_API_KEY = process.env.REACT_APP_AMPLITUDE_API_KEY ?? '';

export const logUserInformation = (userInfo: Record<string, string>) => {
	const userData = new amplitude.Identify();

	const keys = Object.keys(userInfo);
	keys.forEach((property) => {
		userData.set(property, userInfo[property]);
	});

	amplitude.identify(userData);
};

export const logEvent = (
	eventName: string,
	eventProperties?: Record<string, string>
) => {
	amplitude.logEvent(eventName, eventProperties);
};

export const logSetUserId = (userId: string) => {
	amplitude.setUserId(userId);
};

export const initLogger = () => {
	amplitude.init(AMPLITUDE_API_KEY, { autocapture: true });

	const sessionReplay = sessionReplayPlugin({
		// should be lower for production, in heavy use
		sampleRate: 1,
	});

	amplitude.add(sessionReplay);
};
