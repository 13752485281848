import { Flex, Progress, Stack, Text } from '@chakra-ui/react';
import { SchemaStoryResponseDto } from '@jam/api-sdk';
import { useTranslation } from 'react-i18next';

type CallerHeaderProps = {
	story: SchemaStoryResponseDto;
	allItems: number;
	completedItems: number;
	numberOfRounds: number;
};

export const CallerHeader = ({
	story,
	allItems,
	completedItems,
	numberOfRounds,
}: CallerHeaderProps) => {
	const { t } = useTranslation(['call', 'story']);

	return (
		<Flex direction={'column'} maxW="700px">
			<Text fontSize={'20px'} fontWeight={'medium'}>
				{story.title}
			</Text>
			<Stack
				gap={1}
				alignItems={'center'}
				direction={'row'}
				divider={<Text>·</Text>}
			>
				<Text color="#757575" fontSize={'12px'}>
					{story.persona.name}
				</Text>
				<Text color="#757575" fontSize={'12px'}>
					{story.persona.role}{' '}
					{story.persona.workplace_name && `@${story.persona.workplace_name}`}
				</Text>
				{story.situation.conversation_type && (
					<Text color="#757575" fontSize={'12px'}>
						{t(`story:${story.situation.conversation_type}`)}
					</Text>
				)}
				{story.duration && (
					<Text color="#757575" fontSize={'12px'}>
						{t('story:duration_value', { duration: story.duration })}
					</Text>
				)}
				{story.difficulty && (
					<Text color="#757575" fontSize={'12px'}>
						{t(`story:${story.difficulty}`)}
					</Text>
				)}
			</Stack>
			{allItems > 0 && (
				<Flex mt="5" gap={'2'} alignItems={'center'}>
					<Progress
						border={'1px'}
						size={'md'}
						borderColor={'#D9D9D9'}
						borderRadius={'24px'}
						w={'300px'}
						value={completedItems}
						max={allItems}
					/>
					<Stack
						gap={1}
						alignItems={'center'}
						direction={'row'}
						divider={<Text>·</Text>}
					>
						<Text fontSize={'12px'} color="#4241E4" fontWeight={'semibold'}>
							{t('solved_tasks_number', {
								completedItems,
								allItems,
							})}
						</Text>
						<Text fontSize={'12px'} color="#757575">
							{t('rounds_played', { count: numberOfRounds })}
						</Text>
					</Stack>
				</Flex>
			)}
		</Flex>
	);
};
