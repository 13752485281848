import { useToast } from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	SchemaUserOverviewDto,
	WhoAmIResponseDTORole,
} from '../../../../../../../../packages/api-sdk/src';
import { OrgMemberInfo } from '../../../../../common/types/AuthTypes';
import { useChangeUserRoleMutation } from '../../../../../redux/api/crmApi';
import { useGetUsersOverviewQuery } from '../../../../../redux/api/dashboardApi';

export const useRegisteredMembersTable = ({
	org,
}: {
	org: OrgMemberInfo | undefined;
}): {
	search: string;
	setSearch: (value: string) => void;
	setSelectedTeamId: (value: string | undefined) => void;
	data: SchemaUserOverviewDto[] | undefined;
	isLoading: boolean;
	customFilter: { id: string; value: string }[];
	onRoleChange: (userId: string, role: WhoAmIResponseDTORole) => Promise<void>;
} => {
	const toast = useToast();
	const { t } = useTranslation(['common', 'team']);
	const [search, setSearch] = useState('');
	const [selectedTeamId, setSelectedTeamId] = useState<string | undefined>(
		undefined
	);
	const customFilter = [{ id: 'email', value: search }];

	const { data, isLoading } = useGetUsersOverviewQuery(selectedTeamId);
	const [changeRole] = useChangeUserRoleMutation();

	const onRoleChange = async (
		userId: string,
		role: WhoAmIResponseDTORole
	): Promise<void> => {
		if (!org) return;
		await changeRole({ userId, orgId: org.orgId, role });
		toast({
			title: t('role-changed-successfully'),
			status: 'success',
			duration: 3000,
			isClosable: true,
		});
	};
	return {
		search,
		setSearch,
		setSelectedTeamId,
		data,
		isLoading,
		customFilter,
		onRoleChange,
	};
};
