import { useAuthInfo } from '@propelauth/react';
import { useUserOrg } from './useUserOrg';

/**
 * checks if the user is a manager or owner in any of the organizations
 * @returns the orgId of the organization where the user is a manager or owner
 */
export const useIsManagerOrOwnerAtOrg = (): string | undefined => {
	const authInfo = useAuthInfo();
	const org = useUserOrg();

	if (!org) return;

	const isManagerOrOwner =
		authInfo.accessHelper?.isRole(org.orgId, 'Owner') ||
		authInfo.accessHelper?.isRole(org.orgId, 'Manager');

	if (isManagerOrOwner) {
		return org.orgId;
	}

	return;
};
