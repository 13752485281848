import { Flex } from '@chakra-ui/react';
import { CompletionAcrossUsers } from './CompletionAcrossUsers';
import { WeeklyActivity } from './WeeklyActivity';
import { MostAndLeastActiveUsers } from '../user-activity/MostAndLeastActiveUsers';

export const TeamActivity = ({
	selectedTeamId,
}: {
	selectedTeamId: string | undefined;
}) => {
	return (
		<Flex direction={'column'} gap={6} py="6">
			<CompletionAcrossUsers teamId={selectedTeamId} />
			<WeeklyActivity teamId={selectedTeamId} />
			<MostAndLeastActiveUsers teamId={selectedTeamId} />
		</Flex>
	);
};
