/* eslint-disable @typescript-eslint/no-misused-promises */
import {
	Box,
	Flex,
	Input,
	SkeletonText,
	Stack,
	useToast,
} from '@chakra-ui/react';
import {
	SchemaPendingInviteResponseDto,
	WhoAmIResponseDTORole,
} from '@jam/api-sdk';
import { createColumnHelper } from '@tanstack/react-table';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import CustomTable from '../../../../../common/components/custom-table/CustomTable';
import { CreatedAtCell } from '../../../../../common/components/custom-table/cells/common/CreatedAtCell';
import { InviteSendReminderCell } from '../../../../../common/components/custom-table/cells/manager/InviteSendReminderCell';
import { InviteStatusCell } from '../../../../../common/components/custom-table/cells/manager/InviteStatusCell';
import { UserEmailCell } from '../../../../../common/components/custom-table/cells/user/UserEmailCell';
import { UserRoleCell } from '../../../../../common/components/custom-table/cells/user/UserRoleCell';
import { useInviteUserToOrganizationMutation } from '../../../../../redux/api/crmApi';
import { useGetPendingInvitesData } from './useGetPendingInvitesData';

const LoadingState = () => {
	return (
		<Flex role="loading" mt="5" direction={'column'} gap="6">
			<Box maxW="300px">
				<SkeletonText noOfLines={1} height={'30px'} />
			</Box>
			<SkeletonText w={'full'} noOfLines={5} spacing={'5'} />
		</Flex>
	);
};

export const PendingInvites = ({ orgId }: { orgId: string }) => {
	const { t } = useTranslation(['settings', 'common', 'team']);
	const toast = useToast();
	const { isLoading, userData, customFilter, search, setSearch } =
		useGetPendingInvitesData({ orgId });

	const [inviteUserToOrg] = useInviteUserToOrganizationMutation();
	const onInviteClickHandler = useCallback(
		async (email: string, role?: WhoAmIResponseDTORole) => {
			const success = await inviteUserToOrg({
				orgId,
				email,
				role: role ?? WhoAmIResponseDTORole.Member,
			});
			if (success) {
				toast({
					title: t('user-invited-successfully'),
					status: 'success',
					duration: 3000,
					isClosable: true,
				});
			}
		},
		[inviteUserToOrg, toast]
	);

	const columnHelper = createColumnHelper<SchemaPendingInviteResponseDto>();
	const columns = [
		columnHelper.accessor('email', {
			header: t('common:email').toString(),
			cell: ({ row }) => <UserEmailCell row={row.original} />,
		}),
		columnHelper.accessor('role', {
			header: t('common:role').toString(),
			cell: ({ row }) => <UserRoleCell row={row.original} />,
		}),
		columnHelper.display({
			header: t('common:status').toString(),
			cell: ({ row }) => <InviteStatusCell row={row.original} />,
		}),
		columnHelper.accessor('createdAt', {
			header: t('team:date_invited').toString(),
			cell: ({ row }) => <CreatedAtCell row={row.original} />,
		}),
		columnHelper.display({
			id: 'sendReminder',
			enableSorting: false,
			enableMultiSort: false,
			sortingFn: undefined,
			header: '',
			cell: ({ row }) => (
				<InviteSendReminderCell
					row={row.original}
					onClick={onInviteClickHandler}
				/>
			),
		}),
	];

	if (isLoading) {
		return <LoadingState />;
	}

	return (
		<Stack direction="column" spacing="5">
			{userData.length > 0 && (
				<CustomTable<SchemaPendingInviteResponseDto>
					data={userData}
					columns={columns}
					customFilters={customFilter}
					withWrapper
					paginationPosition="bottom"
					topbarContent={
						<Flex gap="5">
							<Input
								role="search"
								width={'280px'}
								value={search}
								onChange={(e) => setSearch(e.target.value)}
								placeholder={t('team:member_search_placeholder') ?? ''}
							/>
						</Flex>
					}
				/>
			)}
		</Stack>
	);
};
