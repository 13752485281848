import { Pagination } from './Pagination';

type Props = {
	showPagination?: boolean;
	rowsPerPage: number;
	page: number;
	numberOfPages: number;
	setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
	setPage: React.Dispatch<React.SetStateAction<number>>;
};

const CustomTableFooter = ({
	showPagination,
	rowsPerPage,
	setRowsPerPage,
	page,
	setPage,
	numberOfPages,
}: Props) => {
	if (!showPagination) return null;

	return (
		<Pagination
			page={page}
			setPage={setPage}
			rowsPerPage={rowsPerPage}
			numberOfPages={numberOfPages}
			setRowsPerPage={setRowsPerPage}
		/>
	);
};

export default CustomTableFooter;
