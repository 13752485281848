import {
	Avatar,
	Box,
	Flex,
	Input,
	Skeleton,
	Stack,
	Text,
} from '@chakra-ui/react';
import { SchemaSessionsTeamActivityReposeDto } from '@jam/api-sdk';
import { createColumnHelper } from '@tanstack/react-table';
import { formatDate } from 'date-fns';
import { t } from 'i18next';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SolvedItemDots } from '../../../../../common/components/SolvedItemDots';
import CustomTable from '../../../../../common/components/custom-table/CustomTable';
import { useGetStoriesQuery } from '../../../../../redux/api/cmsApi';
import { useGetRolePlayActivityQuery } from '../../../../../redux/api/dashboardApi';

const LoadingState = () => {
	return (
		<Flex role="placeholder" direction={'column'} gap="4">
			<Box maxW="300px">
				<Skeleton borderRadius={'8px'} h="30px" flex={1} />
			</Box>
			<Box display={'flex'} flexDirection={'column'} h="200px" w="100%" gap={2}>
				<Skeleton borderRadius={'8px'} h="30px" flex={1} />
				<Skeleton borderRadius={'8px'} h="30px" flex={1} />
				<Skeleton borderRadius={'8px'} h="30px" flex={1} />
				<Skeleton borderRadius={'8px'} h="30px" flex={1} />
				<Skeleton borderRadius={'8px'} h="30px" flex={1} />
			</Box>
		</Flex>
	);
};

export const RoleplayAcrossUsersTable = ({
	selectedDateFilter,
	teamId,
}: {
	teamId: string | undefined;
	selectedDateFilter: 'current_week' | 'current_month' | 'all_time' | undefined;
}) => {
	const [search, setSearch] = useState('');
	const navigate = useNavigate();
	const customFilter = [{ id: 'userName', value: search }];
	const columnHelper =
		createColumnHelper<SchemaSessionsTeamActivityReposeDto>();
	const { data, isLoading } = useGetRolePlayActivityQuery({
		teamId,
		dateFilter: selectedDateFilter ?? 'all_time',
	});
	const { data: stories, isLoading: storiesLoading } = useGetStoriesQuery();

	if (isLoading || storiesLoading) return <LoadingState />;
	if (!data || !stories) return null;

	const columns = [
		columnHelper.accessor('createdAt', {
			header: t('common:date').toString(),
			cell: ({ row }) => {
				if (!row.original.createdAt) return null;
				return (
					<Box gap="4px" alignItems="start" width="280px">
						<Text fontWeight={500} fontSize="13px">
							{formatDate(row.original.createdAt, 'MMM d, yyyy')}
						</Text>
						<Text fontSize={'13px'} color={'#757575'}>
							{formatDate(row.original.createdAt, 'h:mm a').toLowerCase()}
						</Text>
					</Box>
				);
			},
		}),
		columnHelper.accessor('userName', {
			header: t('dashboard:team_member').toString(),
			cell: ({ row }) => (
				<Flex alignItems={'center'} gap="12px" width={'280px'}>
					<Avatar
						bg="gray"
						size="sm"
						name={row.original.userName}
						src={row.original.userPictureUrl}
					/>

					<Stack direction="column" alignItems="start" gap={'4px'}>
						<Text>{row.original.userName}</Text>
						<Text fontSize={'13px'} color={'#757575'}>
							{row.original.teamName}
						</Text>
					</Stack>
				</Flex>
			),
		}),
		columnHelper.accessor('storyReferenceId', {
			header: t('common:mission').toString(),
			cell: ({ row }) => {
				const story = stories.find(
					(x) => x.id === row.original.storyReferenceId
				);
				if (!story) return '-';

				return (
					<Text width={'240px'} isTruncated>
						{story.title}
					</Text>
				);
			},
		}),
		columnHelper.accessor('score', {
			header: t('common:score').toString(),
			cell: ({ row }) => (
				<Flex direction="row" justifyContent="end" width={'100%'}>
					<Text px="1" borderRadius={'full'}>
						{Math.round(row.original.score)}
					</Text>
				</Flex>
			),
		}),
		columnHelper.accessor('results', {
			header: t('common:results').toString(),
			cell: ({ row }) => {
				return <SolvedItemDots showMax={4} items={row.original.results} />;
			},
		}),
	];
	const finishedSessions = data.filter((x) => x.results.length > 0);

	return (
		<Flex gap={6} direction={'column'}>
			<Stack mt="5" direction="column" spacing="5">
				<CustomTable<SchemaSessionsTeamActivityReposeDto>
					topbarContent={
						<Flex gap="5">
							<Input
								role="search"
								maxW={'300'}
								value={search}
								onChange={(e) => setSearch(e.target.value)}
								borderRadius={'8px'}
								placeholder={t('common:search_by_name') ?? ''}
							/>
						</Flex>
					}
					withWrapper
					data={finishedSessions}
					columns={columns}
					defaultSorting={[{ id: 'createdAt', desc: true }]}
					customFilters={customFilter}
					onRowClick={(row) => {
						navigate(
							`/call?storyId=${row.storyReferenceId}&learnerId=${row.userId}&ref=dashboard&sessionId=${row.sessionId}`
						);
					}}
					paginationPosition="bottom"
				/>
			</Stack>
		</Flex>
	);
};
