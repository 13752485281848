import { TFunction } from 'i18next';

export type TimeUnit =
	| 'year'
	| 'month'
	| 'week'
	| 'day'
	| 'hour'
	| 'minute'
	| 'second';

// Not using dynamic keys for static analysis tools
const datesLangDict: Record<TimeUnit, string> = {
	year: 'common:dates.years',
	month: 'common:dates.months',
	week: 'common:dates.weeks',
	day: 'common:dates.days',
	hour: 'common:dates.hours',
	minute: 'common:dates.minutes',
	second: 'common:dates.seconds',
};
export function dateAsNaturalLanguage(date: string): {
	unit: TimeUnit;
	value: number;
} {
	const diffInSeconds = Math.floor(
		(Date.now() - new Date(date).getTime()) / 1000
	);

	// Define the units using the TimeUnit type
	const units: { unit: TimeUnit; seconds: number }[] = [
		{ unit: 'year', seconds: 31536000 },
		{ unit: 'month', seconds: 2592000 },
		{ unit: 'week', seconds: 604800 },
		{ unit: 'day', seconds: 86400 },
		{ unit: 'hour', seconds: 3600 },
		{ unit: 'minute', seconds: 60 },
		{ unit: 'second', seconds: 1 }, // Added seconds for completeness
	];

	for (const { unit, seconds } of units) {
		const count = Math.floor(diffInSeconds / seconds);
		if (count >= 1) {
			return { unit, value: count };
		}
	}

	// Fallback to 'second' if the difference is less than a second
	return { unit: 'second', value: diffInSeconds };
}

export function dateAgoAsString(t: TFunction, date: string): string {
	// if future -> return '', should not be used for future dates
	if (new Date(date) > new Date()) {
		return '';
	}
	const { unit, value } = dateAsNaturalLanguage(date);

	const unitTranslation = t(datesLangDict[unit]);

	return t('common:dates.units_ago', { count: value, unit: unitTranslation });
}
