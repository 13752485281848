import {
	SchemaCompletedUserActivityReposeDto,
	SchemaCompletionsWithTrendsResponseDto,
	SchemaDetailedUserOverviewResponseDto,
	SchemaKeyStoryForImprovementDto,
	SchemaSessionsTeamActivityReposeDto,
	SchemaTeamResponseDto,
	SchemaUserOverviewDto,
	SchemaUserPerformanceResponseDto,
	SchemaWeeklyActivityAcrossAllUsersResponseDto,
} from '@jam/api-sdk';
import { createApi } from '@reduxjs/toolkit/query/react';
import { DashboardDateFilter } from '../../features/dashboard/types/DashboardFilteringTypes';
import { buildBaseQuery } from './buildBaseQuery';

const createTeamLink = (
	link: string,
	teamId: string | undefined,
	extraParams?: Record<string, string>
): string => {
	// Start with the base link, optionally adding the teamId parameter
	const fullLink = teamId ? `${link}?teamId=${teamId}` : link;

	// If extra parameters are provided, append them correctly
	if (extraParams) {
		const params = new URLSearchParams(extraParams);
		// Use `?` if no `teamId` is present, otherwise `&`
		return `${fullLink}${teamId ? '&' : '?'}${params.toString()}`;
	}

	return fullLink;
};

const api = createApi({
	reducerPath: 'dashboard-api',
	baseQuery: buildBaseQuery('/api/v1'),
	endpoints: (builder) => ({
		getSessionActivity: builder.query<
			SchemaCompletedUserActivityReposeDto,
			string | undefined
		>({
			query: (teamId: string | undefined) =>
				createTeamLink('user-activity/session-activity', teamId),
		}),
		getTeamCompletions: builder.query<
			SchemaCompletionsWithTrendsResponseDto,
			string | undefined
		>({
			query: (teamId: string | undefined) =>
				createTeamLink('team-activity/completions', teamId),
		}),
		getWeeklyActivity: builder.query<
			SchemaWeeklyActivityAcrossAllUsersResponseDto,
			string | undefined
		>({
			query: (teamId: string | undefined) =>
				createTeamLink('team-activity/weekly-activity', teamId),
		}),
		getRolePlayActivity: builder.query<
			SchemaSessionsTeamActivityReposeDto[],
			{
				teamId?: string;
				dateFilter?: DashboardDateFilter;
			}
		>({
			// Generate the query URL with both teamId and dateFilter
			query: ({ teamId, dateFilter = 'all_time' }) =>
				createTeamLink('team-activity/all-sessions', teamId, { dateFilter }),
		}),
		getDetailedUserKeyMissionsForImprovement: builder.query<
			SchemaKeyStoryForImprovementDto[],
			string
		>({
			query: (userId: string) =>
				`detailed-user-activity/key-stories?userId=${userId}`,
		}),
		getDetailedUserActivityOverview: builder.query<
			SchemaDetailedUserOverviewResponseDto,
			string
		>({
			query: (userId: string) =>
				`detailed-user-activity/overview?userId=${userId}`,
		}),
		getDetailedUserPerformance: builder.query<
			SchemaUserPerformanceResponseDto[],
			string
		>({
			query: (userId: string) =>
				`detailed-user-activity/performance?userId=${userId}`,
		}),
		getDetailedUserCompletions: builder.query<
			SchemaCompletionsWithTrendsResponseDto,
			string
		>({
			query: (userId: string) =>
				`detailed-user-activity/completions?userId=${userId}`,
		}),
		getOrgTeams: builder.query<SchemaTeamResponseDto[], { orgId: string }>({
			query: ({ orgId }: { orgId: string }) => `team/organization/${orgId}`,
		}),
		getUsersOverview: builder.query<
			SchemaUserOverviewDto[],
			string | undefined
		>({
			query: (teamId: string | undefined) =>
				createTeamLink('team-activity/users-overview', teamId),
		}),
	}),
});

export const {
	useGetSessionActivityQuery,
	useGetTeamCompletionsQuery,
	useGetWeeklyActivityQuery,
	useGetRolePlayActivityQuery,
	useGetDetailedUserActivityOverviewQuery,
	useGetDetailedUserKeyMissionsForImprovementQuery,
	useGetDetailedUserCompletionsQuery,
	useGetOrgTeamsQuery,
	useGetDetailedUserPerformanceQuery,
	useGetUsersOverviewQuery,
} = api;

export default api;
