import {
	SchemaPendingInviteResponseDto,
	SchemaUserResponseDto,
	WhoAmIResponseDTORole,
} from '@jam/api-sdk';
import { createApi } from '@reduxjs/toolkit/query/react';
import { buildBaseQuery } from './buildBaseQuery';

const api = createApi({
	reducerPath: 'crm-api',
	tagTypes: ['organization-users', 'pending-invites'],
	baseQuery: buildBaseQuery('/api/v1/settings'),
	endpoints: (builder) => ({
		/**
		 * @see file://./../../../../api/src/crm/exports/controllers/settings.controller.ts
		 */
		getInvitedUsers: builder.query<
			SchemaPendingInviteResponseDto[],
			{ orgId: string }
		>({
			providesTags: ['pending-invites'],
			query: ({ orgId }) => `org/${orgId}/pending-invites`,
		}),
		/**
		 * @see file://./../../../../api/src/crm/exports/controllers/settings.controller.ts
		 */
		getOrganizationUsers: builder.query<SchemaUserResponseDto[], void>({
			providesTags: ['organization-users'],
			query: () => 'org/users',
		}),
		/**
		 * @see file://./../../../../api/src/crm/exports/controllers/settings.controller.ts
		 */
		getManageableUsers: builder.query<SchemaUserResponseDto[], void>({
			providesTags: ['organization-users'],
			query: () => 'org/manager/users',
		}),
		completeOnboarding: builder.mutation<void, { feature: string }>({
			query: ({ feature }) => ({
				url: `complete-onboarding/${feature}`,
				method: 'PATCH',
			}),
		}),
		/**
		 * @see file://./../../../../api/src/crm/exports/controllers/settings.controller.ts
		 */
		changeUserRole: builder.mutation<
			void,
			{ userId: string; orgId: string; role: WhoAmIResponseDTORole }
		>({
			invalidatesTags: ['organization-users'],
			query: ({ userId, orgId, role }) => ({
				url: `org/${orgId}/change-role`,
				method: 'PATCH',
				body: {
					userId,
					role,
				},
			}),
			async onQueryStarted({ userId, role }, { dispatch, queryFulfilled }) {
				const patchResult = dispatch(
					api.util.updateQueryData(
						'getOrganizationUsers',
						undefined,
						(draft) => {
							const user = draft.find((x) => x.id === userId);
							if (user) {
								user.role = role;
							}
						}
					)
				);
				try {
					await queryFulfilled;
				} catch {
					patchResult.undo();
				}
			},
		}),
		/**
		 * @see file://./../../../../api/src/crm/exports/controllers/settings.controller.ts
		 */
		inviteUserToOrganization: builder.mutation<
			void,
			{ orgId: string; email: string; role: string }
		>({
			invalidatesTags: ['pending-invites'],
			query: ({ orgId, email, role }) => ({
				url: `org/${orgId}/invite-user`,
				method: 'POST',
				body: {
					email,
					role,
				},
			}),
		}),
		/**
		 * @see file://./../../../../api/src/crm/exports/controllers/profile.controller.ts
		 */
		changeLanguage: builder.mutation<
			void,
			{
				language: string;
			}
		>({
			query: (body) => ({
				url: 'change-language',
				method: 'PATCH',
				body,
			}),
		}),
		/**
		 * @see file://./../../../../api/src/crm/exports/controllers/profile.controller.ts
		 */
		changeAccountInfo: builder.mutation<
			void,
			{
				firstName: string;
				lastName: string;
			}
		>({
			query: (body) => ({
				url: 'change-name',
				method: 'PATCH',
				body,
			}),
		}),
		/**
		 * @see file://./../../../../api/src/crm/exports/controllers/profile.controller.ts
		 */
		changeAccountEmail: builder.mutation<
			void,
			{
				newEmail: string;
			}
		>({
			query: (body) => ({
				url: 'change-email',
				method: 'PATCH',
				body,
			}),
		}),
		/**
		 * @see file://./../../../../api/src/crm/exports/controllers/profile.controller.ts
		 */
		changePassword: builder.mutation<
			void,
			{
				password: string;
			}
		>({
			query: (body) => ({
				url: 'change-password',
				method: 'PATCH',
				body,
			}),
		}),
	}),
});

export const {
	useGetInvitedUsersQuery,
	useChangeLanguageMutation,
	useChangePasswordMutation,
	useChangeAccountInfoMutation,
	useChangeAccountEmailMutation,
	useGetOrganizationUsersQuery,
	useInviteUserToOrganizationMutation,
	useChangeUserRoleMutation,
	useCompleteOnboardingMutation,
	useGetManageableUsersQuery,
} = api;
export default api;
