import {
	Button,
	Flex,
	Tab,
	TabIndicator,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { Lock, PencilSimple } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Chip } from '../../../common/components/Chip';
import { MissionTaskList } from '../../../common/components/MissionTaskList';
import { useGetStoryDetails } from '../../../common/hooks/useGetStoryDetails';
import { RequestEditsModal } from './RequestEditsModal';

export const MissionDetail = () => {
	const { missionId } = useParams();
	const mission = useGetStoryDetails(missionId);
	const { t } = useTranslation('common');
	const { isOpen, onOpen, onClose } = useDisclosure();

	if (!mission) {
		return null;
	}

	const tasks = mission.items_conversation_feedback;

	return (
		<Flex direction="column" gap="3">
			<Flex justifyContent="space-between" alignItems="center">
				<Text fontWeight={'bold'} fontSize={'40px'}>
					{mission.title}
				</Text>
				<Button
					leftIcon={<PencilSimple size={16} />}
					bg="#4241E4"
					color="white"
					_hover={{ bg: '#4241E4' }}
					size="sm"
					fontSize="14px"
					onClick={() => onOpen()}
				>
					{t('requestEdits')}
				</Button>
			</Flex>
			<Text fontWeight={'500'} fontSize={'16px'}>
				{mission.goal}
			</Text>
			<Text
				color={'#757575'}
				fontSize={'16px'}
				lineHeight={'24px'}
				maxW={'700px'}
			>
				{mission.description}
			</Text>
			{mission.conversation_topic.length > 0 && (
				<Flex mt={3} gap={2}>
					{mission.conversation_topic.map((tag: string) => (
						<Chip
							key={tag}
							bg={'#FAFAFA'}
							fontSize={'13px'}
							color={'#757575'}
							text={tag}
						/>
					))}
				</Flex>
			)}
			<Tabs mt="3" isLazy>
				<TabList>
					<Tab
						fontWeight={'medium'}
						_selected={{ color: '#4241E4' }}
						fontSize={'16px'}
					>
						{t('tasks')}
					</Tab>
					<Tab
						isDisabled
						fontWeight={'medium'}
						_selected={{ color: '#4241E4' }}
						fontSize={'16px'}
					>
						<Flex align={'center'} gap={2}>
							<Text>{t('activity')}</Text>
							<Lock size={16} />
						</Flex>
					</Tab>
				</TabList>
				<TabIndicator
					mt="-1.5px"
					height="2px"
					bg="#4241E4"
					borderRadius="1px"
				/>
				<TabPanels>
					<TabPanel px="0">
						<MissionTaskList tasks={tasks} />
					</TabPanel>
				</TabPanels>
			</Tabs>
			<RequestEditsModal isOpen={isOpen} onClose={onClose} />
		</Flex>
	);
};
