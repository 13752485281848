import {
	Avatar,
	Box,
	Button,
	Divider,
	Flex,
	HStack,
	Image,
	Stack,
	Text,
	useToast,
} from '@chakra-ui/react';
import {
	CreateAssignContentRequestDTOReferenceType,
	SchemaStoryResponseDto,
} from '@jam/api-sdk';
import { Clock, Play, PushPin } from '@phosphor-icons/react';
import { skipToken } from '@reduxjs/toolkit/query';
import { formatDistance } from 'date-fns';
import { de } from 'date-fns/locale/de';
import { enGB } from 'date-fns/locale/en-GB';
import { useTranslation } from 'react-i18next';
import {
	useAssignContentMutation,
	useGetAssignedContentAllocationQuery,
} from '../../../redux/api/contentAllocationApi';
import { useDifficultyIcon } from '../../home/hooks/useDifficultyIcon';
import { useGetLearnerLastSessionPerStory } from '../hooks/useGetLearnerLastSessionPerStory';
import { useLearnerId } from '../hooks/useLearnerId';

type ManagerStoryHeaderProps = {
	story: SchemaStoryResponseDto;
	userId?: string;
};

export const ManagerStoryHeader = ({
	story,
	userId,
}: ManagerStoryHeaderProps) => {
	const { t, i18n } = useTranslation('story');
	const locale = i18n.language === 'en' ? enGB : de;
	const [assignContentToUser, { isLoading }] = useAssignContentMutation();
	const toast = useToast();
	const { learnerId } = useLearnerId();
	const persona = story.persona;
	const difficultyIcon = useDifficultyIcon(story.difficulty);
	const lastSession = useGetLearnerLastSessionPerStory(story.id, learnerId);
	const { data: assignedContent } = useGetAssignedContentAllocationQuery(
		userId ?? skipToken
	);

	const onAssignContent = async () => {
		if (!learnerId) return;
		await assignContentToUser({
			userIds: [learnerId],
			reference: {
				id: story.id,
				type: CreateAssignContentRequestDTOReferenceType.story,
			},
		});
		toast({
			title: t('content_assigned_title'),
			description: t('content_assigned_description'),
			status: 'success',
		});
	};

	const assignedStoryIds = (assignedContent ?? [])
		.filter(
			(assignment) =>
				assignment.reference.type ===
				CreateAssignContentRequestDTOReferenceType.story
		)
		.map((assignment) => assignment.reference.id);

	return (
		<Box mt={1}>
			<Flex justify="space-between" w="100%">
				<Flex direction={'column'} gap={4} flex={1}>
					<Flex justify={'space-between'} align={'center'} gap="1">
						<Text fontSize="40px" fontWeight={'bold'} flex={1}>
							{story.title}
						</Text>
						<HStack height={'fit-content'} flexShrink={0} spacing={3}>
							<Button
								isDisabled={assignedStoryIds.includes(story.id)}
								isLoading={isLoading}
								onClick={() => {
									void onAssignContent();
								}}
								_hover={{
									bg: 'brand.500',
								}}
								leftIcon={<PushPin />}
							>
								{t('assign_mission')}
							</Button>
						</HStack>
					</Flex>
					<Text fontSize="16px" fontWeight={'500'}>
						{story.goal}
					</Text>
				</Flex>
			</Flex>

			{/* Story Details Section */}
			<Box borderWidth="1px" borderRadius="lg" mt={4} p={4} bg="white">
				<Flex align="center" justify="space-between">
					<Stack
						w="100%"
						direction="row"
						alignItems="center"
						spacing={4}
						divider={<Divider orientation="vertical" height="50px" />}
					>
						<Flex flex={1} align="center" gap={4}>
							<Avatar
								src={persona.avatar_file.url}
								width={'40px'}
								height={'40px'}
								name={persona.name}
							/>
							<Box flex={1}>
								<Text fontWeight={'500'} fontSize={'16px'}>
									{persona.name}
								</Text>
								<Text fontSize="12px" color="#757575">
									{story.persona.role}{' '}
									{story.persona.workplace_name &&
										`@${story.persona.workplace_name}`}
								</Text>
							</Box>
						</Flex>
						<Flex alignItems="center" flex={1}>
							<Flex alignItems="center" gap={5}>
								{difficultyIcon && (
									<Image src={difficultyIcon} width={'24px'} />
								)}
								<Flex direction={'column'} gap={2}>
									<Text fontSize="12px" color="#757575">
										{t('difficulty_label')}
									</Text>
									<Text fontSize={'14px'} fontWeight={'500'}>
										{t(story.difficulty)}
									</Text>
								</Flex>
							</Flex>
						</Flex>
						<Flex alignItems="center" flex={1}>
							<Flex alignItems="center" gap={5}>
								<Clock size={24} />
								<Flex direction={'column'} gap={2}>
									<Text fontSize="12px" color="#757575">
										{t('duration_label')}
									</Text>
									<Text fontSize={'14px'} fontWeight={'500'}>
										{t('duration_value', {
											duration: story.duration,
										})}
									</Text>
								</Flex>
							</Flex>
						</Flex>
						<Flex alignItems="center" flex={1}>
							<Flex alignItems="center" gap={5}>
								<Play size={24} />
								<Flex direction={'column'} gap={2}>
									<Text fontSize="12px" color="#757575">
										{t('type_label')}
									</Text>
									<Text fontSize={'14px'} fontWeight={'500'}>
										{t(story.situation.conversation_type)}
									</Text>
								</Flex>
							</Flex>
						</Flex>
						{lastSession && (
							<Flex alignItems="center" flex={1}>
								<Flex alignItems="center" gap={5}>
									<Image src="/icons/chronometer.svg" width={'24px'} />
									<Flex direction={'column'} gap={2}>
										<Text fontSize="12px" color="#757575">
											{t('last_played_label')}
										</Text>
										<Text fontSize={'14px'} fontWeight={'500'}>
											{formatDistance(
												new Date(lastSession.createdAt),
												new Date(),
												{
													addSuffix: true,
													locale,
												}
											)}
										</Text>
									</Flex>
								</Flex>
							</Flex>
						)}
					</Stack>
				</Flex>
			</Box>
		</Box>
	);
};
