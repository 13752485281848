import { useAuthInfo } from '@propelauth/react';
import { useGetWeeklyLeaderboardQuery } from '../../../redux/api/leaderboardApi';

export const useGetWeeklyUserXp = (): number | undefined => {
	const { user } = useAuthInfo();
	const { data, isLoading } = useGetWeeklyLeaderboardQuery();

	if (!user || isLoading) return undefined;

	return data?.find((x) => x.userId === user.userId)?.total ?? 0;
};
