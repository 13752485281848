import {
	Avatar,
	Box,
	Button,
	Flex,
	Skeleton,
	Stack,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { MagnifyingGlass } from '@phosphor-icons/react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { BreadCrumbs } from '../../../../common/components/BreadCrumbs';
import { useGetDetailedUserActivityOverviewQuery } from '../../../../redux/api/dashboardApi';
import { UserSelectModal } from './overview/UserSelectModal';
import { de } from 'date-fns/locale/de';
import { enGB } from 'date-fns/locale/en-GB';

const LoadingState = () => {
	return <Skeleton role="placeholder" mt="10" borderRadius={'24px'} h="50px" />;
};

export const UserSwitcher = () => {
	const { t, i18n } = useTranslation('common');
	const { isOpen, onClose, onOpen } = useDisclosure();
	const locale = i18n.language === 'en' ? enGB : de;
	const { userId } = useParams();
	const { data, isFetching } = useGetDetailedUserActivityOverviewQuery(
		userId as string,
		{
			skip: !userId,
		}
	);

	if (isFetching || !data || !userId) return <LoadingState />;

	return (
		<Box>
			<BreadCrumbs
				backLink="/dashboard"
				items={[
					{
						link: `/dashboard/user/${userId}`,
						title: data.name,
					},
				]}
			/>
			<Flex justifyContent="space-between" alignItems={'center'} mt="4">
				<Flex gap={4} alignItems={'center'}>
					{data && (
						<>
							<Avatar
								bg="gray"
								h="56px"
								w="56px"
								name={data.name}
								src={data.pictureUrl}
							/>
							<Flex direction={'column'}>
								<Text fontSize="40px" fontWeight="bold">
									{data.name}
								</Text>
								<Stack
									direction="row"
									gap={2}
									divider={<Text color="gray.500">•</Text>}
								>
									{data.team && (
										<Text fontSize="16px" color="gray.500">
											{data.team}
										</Text>
									)}
									<Text fontSize="16px" color="gray.500">
										{t('joined_date', {
											date: format(
												new Date(data.registeredSince),
												'MMM d, yyyy',
												{ locale }
											),
										})}
									</Text>
									{data.lastSessionDate && (
										<Text fontSize="16px" color="gray.500">
											{t('last_session_date', {
												date: format(
													new Date(data.lastSessionDate),
													'MMM d, yyyy',
													{ locale }
												),
											})}
										</Text>
									)}
								</Stack>
							</Flex>
						</>
					)}
				</Flex>
				<Button
					_hover={{
						bg: 'brand.500',
					}}
					onClick={onOpen}
					leftIcon={<MagnifyingGlass size="20" />}
				>
					{t('change_user')}
				</Button>
				<UserSelectModal isOpen={isOpen} onClose={onClose} />
			</Flex>
		</Box>
	);
};
