import React from 'react';
import { Text } from '@chakra-ui/react';

export const UserEmailCell: React.FC<{
	row: {
		email?: string | null;
	};
}> = ({ row }) => {
	return <Text fontWeight={'medium'}>{row.email}</Text>;
};
