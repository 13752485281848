import {
	Box,
	Flex,
	IconButton,
	Text,
	Tooltip,
	useDisclosure,
} from '@chakra-ui/react';
import { CaretLeft, X } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { UserWidgets } from '../../../common/components/UserWidgets';
import { useGetUserById } from '../../../common/hooks/useGetUserById';
import { useAppSelector } from '../../../redux/hooks';
import {
	selectCurrentCallStatus,
	setCurrentCallStatus,
} from '../../../redux/slice';
import { useReferer } from '../../home/hooks/useReferer';
import { useLearnerId } from '../hooks/useLearnerId';
import { CallStatus } from './CallStatus';
import { LeaveWarningModal } from './LeaveWarningModal';

type CallHeaderProps = {
	showTeam?: boolean;
};

export const CallHeader = ({ showTeam = true }: CallHeaderProps) => {
	const { t } = useTranslation('call');
	const { isOpen, onClose, onOpen } = useDisclosure();
	const callStatus = useAppSelector(selectCurrentCallStatus);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { ref } = useReferer();
	const { learnerId } = useLearnerId();
	const user = useGetUserById(learnerId);
	let url = '/';
	if (ref) {
		if (ref === 'gym') {
			url = '/gym';
		} else if (ref === 'history') {
			url = '/users/history';
		} else if (ref === 'dashboard') {
			url = '/dashboard';
		} else if (ref === 'user-dashboard' && learnerId) {
			url = `/dashboard/user/${learnerId}`;
		}
	}

	const onKeepPracticing = () => {
		onClose();
	};

	const onExit = () => {
		onClose();
		dispatch(setCurrentCallStatus(null));
		navigate(url);
	};

	const onCloseClick = () => {
		if (
			callStatus === CallStatus.started ||
			callStatus === CallStatus.loading
		) {
			onOpen();
			return;
		}
		dispatch(setCurrentCallStatus(null));
		navigate(url);
	};

	return (
		<Box w="100%" px="10">
			<LeaveWarningModal
				isOpen={isOpen}
				onExit={onExit}
				onContinue={onKeepPracticing}
			/>
			<Flex gap={4} justifyContent={'space-between'} w="100%">
				<Flex gap={2} align={'center'}>
					<Tooltip
						p={3}
						isDisabled={!!learnerId}
						lineHeight={'21px'}
						hasArrow
						maxW={'200px'}
						placement="right"
						bg={'#0B1215'}
						borderRadius={'12px'}
						fontSize={'12px'}
						border={'1px solid #E2E8F0'}
						label="Close call"
						aria-label="Close call"
					>
						<IconButton
							width={'fit-content'}
							size={'xs'}
							bg="gray.100"
							color={'#0B1215'}
							_hover={{ bg: 'gray.200' }}
							aria-label="Back"
							icon={!learnerId ? <X size={20} /> : <CaretLeft size={20} />}
							onClick={onCloseClick}
						/>
					</Tooltip>
					{showTeam && (
						<Flex gap={2} align={'center'}>
							<Text fontSize={'14px'} color={'#757575'}>
								{t('team')}
							</Text>
							<Text fontSize={'14px'} color={'#757575'}>
								/
							</Text>
							<Text fontSize={'14px'} color={'#0B1215'}>
								{user?.firstName} {user?.lastName}
							</Text>
						</Flex>
					)}
				</Flex>
				<UserWidgets />
			</Flex>
		</Box>
	);
};
