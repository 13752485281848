import { Flex } from '@chakra-ui/react';
import { SchemaJourneyResponseDto } from '@jam/api-sdk';
import { Clock } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { Chip } from './Chip';
import { XpIcon } from './Icons';

type JourneyLabelsProps = { journey: SchemaJourneyResponseDto };

export const JourneyLabels = ({ journey }: JourneyLabelsProps) => {
	const { t } = useTranslation('story');

	if (!journey) return null;

	return (
		<Flex flexWrap={'wrap'} gap="2">
			{journey.conversation_topic.map((topic) => (
				<Chip minH={'28px'} bg={'white'} key={topic} text={t(topic)} />
			))}

			{(journey.duration || 0) > 0 && (
				<Chip
					icon={<Clock size={20} />}
					minH={'28px'}
					bg={'white'}
					text={`${journey.duration} min`}
				/>
			)}

			{journey.session_xp && journey.story_xp && (
				<Chip
					border={0}
					bg="transparent"
					minH={'28px'}
					fontWeight="semibold"
					icon={<XpIcon />}
					text={`${journey.session_xp} | ${journey.story_xp}`}
				/>
			)}
		</Flex>
	);
};
