import { skipToken } from '@reduxjs/toolkit/query';
import { useTranslation } from 'react-i18next';
import { useGetFirstNotCompletedStory } from '../../../common/utils/getfirstNotCompletedStory';
import { useGetAssignedContentAllocationQuery } from '../../../redux/api/contentAllocationApi';
import { AssignmentsList } from './AssignmentsList';

export const CurrentAssignments = ({
	userId,
	hideAction,
}: {
	userId?: string;
	hideAction?: boolean;
}) => {
	const { t } = useTranslation('gym');
	const { data: contentAllocations } = useGetAssignedContentAllocationQuery(
		userId ?? skipToken
	);
	const { activeStory } = useGetFirstNotCompletedStory();

	return (
		<AssignmentsList
			hideAction={hideAction}
			title={t('current_track')}
			contentAllocations={contentAllocations}
			activeStory={activeStory ?? undefined}
		/>
	);
};
