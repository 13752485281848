import { SchemaStoryResponseDto } from '@jam/api-sdk';
import { useGetStoriesQuery } from '../../redux/api/cmsApi';

export const useGetStoryDetails = (
	storyId: string | undefined
): SchemaStoryResponseDto | undefined => {
	const { data } = useGetStoriesQuery(undefined, {
		skip: !storyId,
	});

	return data?.find((story) => story.id === storyId);
};
