import React from 'react';
import { Text } from '@chakra-ui/react';

export const UserTotalSessionsCell: React.FC<{
	row: {
		totalSessions?: number;
	};
}> = ({ row }) => {
	return <Text>{row.totalSessions ?? 0}</Text>;
};
