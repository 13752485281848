import {
	Button,
	FormControl,
	FormHelperText,
	FormLabel,
	HStack,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Radio,
	RadioGroup,
	Stack,
	Textarea,
	useToast,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCreateStoryUserFeedbackMutation } from '../../../redux/api/userFeedbackApi';
import { UserFeedback, UserFeedbackSchema } from '../types/FormTypes';

type UserFeedbackModalProps = {
	storyId: string;
	isOpen: boolean;
	onClose: () => void;
};

const UserFeedbackModal = ({
	isOpen,
	onClose,
	storyId,
}: UserFeedbackModalProps) => {
	const toast = useToast();
	const [addUserFeedback] = useCreateStoryUserFeedbackMutation();
	const { t } = useTranslation(['call', 'common']);
	const {
		register,
		handleSubmit,
		control,
		formState: { isValid },
	} = useForm<UserFeedback>({
		resolver: zodResolver(UserFeedbackSchema),
	});

	const onSubmit = async (data: UserFeedback) => {
		await addUserFeedback({
			storyReferenceId: storyId,
			rating: Number(data.rating),
			content: data.feedback,
		});
		onClose();
		toast({
			title: t('call_feedback_success_title'),
			status: 'success',
			duration: 3000,
			isClosable: true,
		});
	};

	return (
		<Modal size={'lg'} isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader fontSize={'18px'}>
					{t('call_feedback_modal_title')}
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody pb={6}>
					<Stack spacing={8}>
						<FormControl as="fieldset">
							<FormLabel as="legend">
								{t('call_feedback_rating_label')}
							</FormLabel>
							<Controller
								control={control}
								name="rating"
								render={({ field }) => (
									<RadioGroup {...field}>
										<HStack spacing="24px">
											<Radio value="1">1</Radio>
											<Radio value="2">2</Radio>
											<Radio value="3">3</Radio>
											<Radio value="4">4</Radio>
											<Radio value="5">5</Radio>
										</HStack>
										<FormHelperText>
											{t('call_feedback_rating_helper')}
										</FormHelperText>
									</RadioGroup>
								)}
							/>
						</FormControl>
						<FormControl as="fieldset">
							<FormLabel as="legend">{t('call_feedback_text_label')}</FormLabel>
							<Textarea {...register('feedback')} size="sm" />
							<FormHelperText>{t('call_feedback_text_helper')}</FormHelperText>
						</FormControl>
					</Stack>
				</ModalBody>
				<ModalFooter>
					<Button
						id="submit-feedback-button"
						type="submit"
						borderRadius={'24px'}
						textColor={'white'}
						gap={'16px'}
						bg={isValid ? '#4241E4' : '#D9D9D9'}
						_hover={{ bg: isValid ? '#4241E4' : '#D9D9D9' }}
						colorScheme="blue"
						onClick={() => {
							void handleSubmit(onSubmit)();
						}}
						mr={3}
					>
						{t('common:submit')}
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default UserFeedbackModal;
