import { Flex, Text } from '@chakra-ui/react';
import React from 'react';
import { AppLayout } from '../../common/components/AppLayout';

export interface BasePageProps {
	title: string;
	titleSuffix?: React.ReactNode;
	children: React.ReactNode;
}
export const BasePage: React.FC<BasePageProps> = ({
	title,
	titleSuffix,
	children,
}) => {
	return (
		<AppLayout>
			<Flex mt={'-8px'} justifyContent={'space-between'}>
				<Text fontWeight={'medium'} fontSize="40px">
					{title}
				</Text>
				{titleSuffix}
			</Flex>
			{children}
		</AppLayout>
	);
};
