import { Box, Button, Flex, Image, Progress, Text } from '@chakra-ui/react';
import { SchemaStoryResponseDto } from '@jam/api-sdk';
import { CaretDown, CaretUp } from '@phosphor-icons/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Chip } from '../../../common/components/Chip';
import { JourneyLabels } from '../../../common/components/JourneyLabels';
import { calculateJourneyCompletion } from '../../../common/utils/calculateCompletion';
import { useGetJourneyQuery } from '../../../redux/api/cmsApi';
import { TrackStoryRow } from './TrackStoryRow';

type TrackAssignmentProps = {
	activeStoryId?: string;
	journeyId: string;
	completedStoryIds: string[];
	hideAction?: boolean;
};

export const TrackAssignment = ({
	activeStoryId,
	journeyId,
	completedStoryIds,
	hideAction = false,
}: TrackAssignmentProps) => {
	const navigate = useNavigate();
	const { t } = useTranslation('gym');
	const { data: journey } = useGetJourneyQuery(journeyId, {
		skip: !journeyId,
	});
	const [isExpanded, setIsExpanded] = useState(false);

	useEffect(() => {
		const storyIds = journey?.stories.map((s) => s.id) ?? [];
		if (activeStoryId && storyIds.includes(activeStoryId)) {
			setIsExpanded(true);
		}
	}, [journey]);

	if (!journey) {
		return null;
	}

	const isActiveJourney =
		activeStoryId && journey.stories.map((s) => s.id).includes(activeStoryId);

	const completion: { completed: number; total: number; percentage: number } =
		calculateJourneyCompletion(journey, completedStoryIds);

	return (
		<Box p="4" border={'1px'} borderColor={'#d9d9d9'} borderRadius={'32px'}>
			<Flex justify={'space-between'}>
				<Text fontSize="24px" fontWeight="medium">
					{journey.title}
				</Text>
				{!hideAction && isActiveJourney && (
					<Box>
						<Button
							id="resume-journey-button"
							borderRadius={'32px'}
							textColor={'white'}
							bg={'#4241E4'}
							gap={'16px'}
							_hover={{ bg: '#4241E4' }}
							onClick={() => {
								navigate(
									`/call?storyId=${activeStoryId}&ref=gym&journey=${journey.title ?? ''}`
								);
							}}
						>
							<Text>{t('resume')}</Text>
							<Image src="/icons/right-arrow.svg" />
						</Button>
					</Box>
				)}
				{(hideAction || !isActiveJourney) && (
					<Button
						variant="plain"
						px={0}
						mx={0}
						onClick={() => setIsExpanded(!isExpanded)}
					>
						<Box>
							{isExpanded ? (
								<CaretUp size={20} weight="bold" />
							) : (
								<CaretDown size={20} weight="bold" />
							)}
						</Box>
					</Button>
				)}
			</Flex>
			<Flex>
				<JourneyLabels journey={journey} />
			</Flex>
			<Flex mt="4" gap={'2'} alignItems={'center'}>
				<Chip
					display={'inline-block'}
					bg={'#D5C9FF'}
					text={`${completion.completed}/${completion.total}`}
				/>
				<Box w="100%">
					<Progress
						borderRadius={'25px'}
						size={'lg'}
						value={completion.completed}
						max={completion.total}
					/>
				</Box>
			</Flex>
			{isExpanded && (
				<Box p="4">
					<Text fontSize={'24px'} fontWeight={'semibold'}>
						{t('missions')}
					</Text>
					<Flex mt="4" direction={'column'} gap="10">
						{journey.stories.map((s: SchemaStoryResponseDto, index: number) => {
							return (
								<TrackStoryRow
									hideAction={hideAction}
									key={s.id}
									number={index + 1}
									isLast={index === journey?.stories.length - 1}
									story={s}
									journeyName={journey?.title}
								/>
							);
						})}
					</Flex>
				</Box>
			)}
		</Box>
	);
};
