import { useTranslation } from 'react-i18next';
import { CompletionStats } from '../../../../../common/components/CompletionStats';
import { useGetTeamCompletionsQuery } from '../../../../../redux/api/dashboardApi';

export const CompletionAcrossUsers = ({
	teamId,
}: {
	teamId: string | undefined;
}) => {
	const { t } = useTranslation('dashboard');
	const { data, isLoading } = useGetTeamCompletionsQuery(teamId);

	return (
		<CompletionStats
			title={t('team_activity_section.completions_title')}
			data={data}
			isLoading={isLoading}
		/>
	);
};
