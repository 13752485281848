import {
	Tab,
	TabIndicator,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useUserOrg } from '../common/hooks/useUserOrg';
import { useGetOrgTeamsQuery } from '../redux/api/dashboardApi';
import { PendingInvites } from '../features/dashboard/components/team/pending-invites/PendingInvites';
import { RegisteredMembers } from '../features/dashboard/components/team/registered-members/RegisteredMembers';
import { BasePage } from './common/BasePage';
import { InviteUsers } from '../features/dashboard/components/team/common/InviteUsers';
import { ContentAssignmentTab } from '../features/settings/components/ContentAssignmentTab';
import { useIsManagerOrOwnerAtOrg } from '../common/hooks/useIsManager';

export const TeamsPage = () => {
	const { t } = useTranslation(['team', 'settings']);
	const org = useUserOrg();
	const { data: teams } = useGetOrgTeamsQuery({ orgId: org?.orgId ?? '' });
	const managedOrgId = useIsManagerOrOwnerAtOrg();

	return (
		<BasePage title={t('team:team_members')} titleSuffix={<InviteUsers />}>
			<Tabs mt="10" isLazy variant={'unstyled'}>
				<TabList borderBottom={0}>
					<Tab
						fontWeight={'medium'}
						_selected={{ color: '#4241E4' }}
						fontSize={'16px'}
					>
						{t('team:registered_members')}
					</Tab>
					<Tab
						fontWeight={'medium'}
						_selected={{ color: '#4241E4' }}
						fontSize={'16px'}
					>
						{t('team:pending_invites')}
					</Tab>
					{managedOrgId && (
						<Tab
							fontWeight={'medium'}
							_selected={{ color: '#4241E4' }}
							fontSize={'16px'}
						>
							{t('settings:content-assignment')}
						</Tab>
					)}
				</TabList>
				<TabIndicator
					mt="-1.5px"
					height="2px"
					bg="#4241E4"
					borderRadius="1px"
				/>
				<TabPanels>
					<TabPanel px="0">
						<RegisteredMembers org={org} teams={teams ?? []} />
					</TabPanel>
					<TabPanel px="0">
						<PendingInvites orgId={org?.orgId ?? ''} />
					</TabPanel>
					{managedOrgId && (
						<TabPanel px="0">
							<ContentAssignmentTab />
						</TabPanel>
					)}
				</TabPanels>
			</Tabs>
		</BasePage>
	);
};
