import { SchemaAssignedContentResponseDto } from '@jam/api-sdk';
import { skipToken } from '@reduxjs/toolkit/query';
import { useGetAssignedContentAllocationQuery } from '../../../redux/api/contentAllocationApi';

type UserProgress = {
	storiesCompleted: number;
	journeysCompleted: number;
};

export const useUserProgress = (
	userId?: string
): UserProgress | null | undefined => {
	const { data, isLoading } = useGetAssignedContentAllocationQuery(
		userId ?? skipToken
	);

	if (isLoading) return undefined;

	if (!data?.length) return null;

	const completedStories = data.flatMap(
		(journeyStatus: SchemaAssignedContentResponseDto) =>
			journeyStatus.completedStoryIds
	);

	const completedJourneys = data.filter(
		(journeyStatus) => journeyStatus.completed
	).length;

	return {
		storiesCompleted: completedStories.length,
		journeysCompleted: completedJourneys,
	};
};
