import { Button, Flex, Image, Text, useToast } from '@chakra-ui/react';
import {
	CreateAssignContentRequestDTOReferenceType,
	SchemaKeyStoryForImprovementDto,
} from '@jam/api-sdk';
import { Clock, PushPin } from '@phosphor-icons/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useGetStoryDetails } from '../../../../../common/hooks/useGetStoryDetails';
import { useAssignContentMutation } from '../../../../../redux/api/contentAllocationApi';

interface KeyImprovementStoryCardProps {
	story: SchemaKeyStoryForImprovementDto;
	assigned: boolean;
}

export const KeyImprovementStoryCard: React.FC<
	KeyImprovementStoryCardProps
> = ({ story, assigned }: KeyImprovementStoryCardProps) => {
	const { t } = useTranslation(['dashboard', 'story']);
	const toast = useToast();
	const storyDetails = useGetStoryDetails(story.storyReferenceId);
	const { userId } = useParams<{ userId: string }>();
	const [assignContentToUser, { isLoading }] = useAssignContentMutation();

	const onAssignContent = async () => {
		if (!userId) return;
		await assignContentToUser({
			userIds: [userId],
			reference: {
				id: story.storyReferenceId,
				type: CreateAssignContentRequestDTOReferenceType.story,
			},
		});
		toast({
			title: t('story:content_assigned_title'),
			description: t('story:content_assigned_description'),
			status: 'success',
		});
	};

	if (!storyDetails) return null;

	const image =
		storyDetails?.persona.avatar_file.sizes.thumbnail?.url ??
		storyDetails?.persona.avatar_file.url;
	return (
		<Flex
			direction={'column'}
			justifyContent={'space-between'}
			border={'1px'}
			w={'full'}
			maxW={'344px'}
			borderColor={'gray.200'}
			borderRadius={'2xl'}
		>
			<Flex direction={'column'} h={'full'}>
				<Image
					w={'full'}
					maxH={'200px'}
					borderTopRadius={'12px'}
					src={image}
					objectFit={'cover'}
				/>
				<Flex direction={'column'} gap="2" pt="2" px="3" h={'full'} pb={4}>
					<Flex flexGrow={1} direction={'column'} gap="2">
						<Text
							display={'flex'}
							alignItems={'center'}
							fontSize={'16px'}
							flexGrow={1}
							fontWeight={'medium'}
						>
							{storyDetails?.title}
						</Text>
						<Flex gap="4" mt="2">
							<Flex gap="1" alignItems={'center'}>
								<Clock size={16} color="gray.500" />
								<Text fontSize={'12px'} color="gray.500">
									{t('story:rounds_played', { count: 3 })}
								</Text>
							</Flex>
							<Flex gap="1" alignItems={'center'}>
								<Image src={'/icons/bar-chart-icon.svg'} alt="bar-chart" />
								<Text fontSize={'12px'} color="gray.500">
									{t('story:average_score', { score: story.averageScore })}
								</Text>
							</Flex>
						</Flex>
						<Button
							mt="2"
							flexGrow={1}
							isLoading={isLoading}
							onClick={() => {
								void onAssignContent();
							}}
							_hover={{
								bg: 'brand.500',
							}}
							leftIcon={<PushPin />}
							isDisabled={assigned}
						>
							{t('story:assign_mission')}
						</Button>
					</Flex>
				</Flex>
			</Flex>
		</Flex>
	);
};
