import {
	Flex,
	Tab,
	TabIndicator,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { AppLayout } from '../../common/components/AppLayout';
import { UserDetailsOverview } from '../../features/dashboard/components/user-details/UserDetailsOverview';
import { UserSwitcher } from '../../features/dashboard/components/user-details/UserSwitcher';
import { UserPerformanceTable } from '../../features/dashboard/components/user-details/performance/UserPerformanceTable';
import { CompletedAssignments } from '../../features/progress/components/CompletedAssignments';
import { CurrentAssignments } from '../../features/progress/components/CurrentAssignments';
import { HistoryTable } from '../../features/user-history/components/HistoryTable';

export const ManagerDashboardUserDetails = () => {
	const { t } = useTranslation('dashboard', { keyPrefix: 'user_details' });
	const { userId } = useParams<{ userId: string }>();
	const navigate = useNavigate();

	return (
		<AppLayout>
			<UserSwitcher />
			<Tabs mt="10" isLazy>
				<TabList>
					<Tab
						fontWeight={'medium'}
						_selected={{ color: '#4241E4' }}
						fontSize={'16px'}
					>
						{t('overview')}
					</Tab>
					<Tab
						fontWeight={'medium'}
						_selected={{ color: '#4241E4' }}
						fontSize={'16px'}
					>
						{t('activity')}
					</Tab>
					<Tab
						fontWeight={'medium'}
						_selected={{ color: '#4241E4' }}
						fontSize={'16px'}
					>
						{t('progress')}
					</Tab>
					<Tab
						fontWeight={'medium'}
						_selected={{ color: '#4241E4' }}
						fontSize={'16px'}
					>
						{t('performance')}
					</Tab>
				</TabList>
				<TabIndicator
					mt="-1.5px"
					height="2px"
					bg="#4241E4"
					borderRadius="1px"
				/>
				<TabPanels>
					<TabPanel px="0">
						<UserDetailsOverview />
					</TabPanel>
					<TabPanel px="0">
						<HistoryTable
							userId={userId}
							onRowClick={(row) => {
								navigate(
									`/call?storyId=${row.storyId}&learnerId=${userId ?? ''}&ref=user-dashboard&sessionId=${row.sessionId}`
								);
							}}
						/>
					</TabPanel>
					<TabPanel px="0">
						<Flex my="10" direction={{ base: 'column' }} gap="5">
							<CurrentAssignments userId={userId} hideAction={true} />
							<CompletedAssignments userId={userId} hideAction={true} />
						</Flex>
					</TabPanel>
					<TabPanel px="0">
						<UserPerformanceTable />
					</TabPanel>
				</TabPanels>
			</Tabs>
		</AppLayout>
	);
};
