import { Skeleton, Stack } from '@chakra-ui/react';
import {
	SessionResponseDTOAnalysisItemsSolved,
	SessionResponseDTOAnalysisState,
} from '@jam/api-sdk';
import { useAuthInfo } from '@propelauth/react';
import { TourProvider } from '@reactour/tour';
import { useEffect, useState } from 'react';
import { AppLayout } from '../common/components/AppLayout';
import { CallPageSteps } from '../common/components/onboarding/OnboardinSteps';
import { OnboardingNavigation } from '../common/components/onboarding/OnboardingNavigation';
import { useIsManagerOrOwnerAtOrg } from '../common/hooks/useIsManager';
import { OnboardingFeature } from '../common/types/Onboarding';
import { CallDetails } from '../features/call/components/CallDetails';
import { CallEndedReasonModal } from '../features/call/components/CallEndedReasonModal';
import { CallHeader } from '../features/call/components/CallHeader';
import { Caller } from '../features/call/components/Caller';
import { ManagerStoryHeader } from '../features/call/components/ManagerStoryHeader';
import { useActiveSession } from '../features/call/hooks/useActiveSession';
import { useLearnerId } from '../features/call/hooks/useLearnerId';
import { useActiveStory } from '../features/home/hooks/useActiveStory';
import { useGetStoryQuery } from '../redux/api/cmsApi';
import {
	useGetSessionQuery,
	useGetStoryLastSessionQuery,
	useGetStorySessionsQuery,
	useLazyGetUserTotalExperienceQuery,
} from '../redux/api/sessionApi';

const LoadingState = () => {
	return (
		<AppLayout hideSidebar fullWidth>
			<CallHeader />
			<Stack direction="column" h="100%" spacing={10} p={10}>
				<Skeleton borderRadius={'8px'} h="200px" flex={1} />
			</Stack>
		</AppLayout>
	);
};

export const CallPage = () => {
	const { activeStory } = useActiveStory();
	const { activeSession } = useActiveSession();
	const { learnerId } = useLearnerId();
	const { user } = useAuthInfo();
	const isManagerOrOwner = useIsManagerOrOwnerAtOrg();
	const [polling, setPolling] = useState(0);
	const [getUserTotalExperience] = useLazyGetUserTotalExperienceQuery();
	const { data: story, isLoading } = useGetStoryQuery(activeStory || '', {
		skip: !activeStory,
	});

	const { data: storySessions } = useGetStorySessionsQuery(activeStory || '', {
		skip: !activeStory,
	});

	const { data: lastSession } = useGetStoryLastSessionQuery(activeStory || '', {
		// skip the query if there is no active story or if there is an active session
		skip: !activeStory || !!activeSession,
		pollingInterval: polling,
	});
	const { data: currentSession } = useGetSessionQuery(activeSession || '', {
		skip: !activeSession,
	});

	const session = activeSession ? currentSession : lastSession;

	// This useEffect is used to set the polling interval for the last session query based on the status of the last session analysis object.
	useEffect(() => {
		if (!session) {
			return;
		}

		// session is stuck in pending for more than 30 minutes give up
		if (new Date(session.createdAt).getTime() + 1000 * 60 * 30 < Date.now()) {
			setPolling(0);
			return;
		}

		if (
			session.analysis === null ||
			session.analysis.state !== SessionResponseDTOAnalysisState.DONE
		) {
			setPolling(3000);
		} else {
			setPolling(0);
			void getUserTotalExperience();
		}
	}, [setPolling, session]);

	if (isLoading) {
		return <LoadingState />;
	}

	if (!story) {
		return null;
	}

	const analysis = session?.analysis;
	const completedItems =
		analysis?.items.filter(
			(item) => item.solved === SessionResponseDTOAnalysisItemsSolved.solved
		).length || 0;
	const allItems = story.items_conversation_feedback.length;

	let showManagerHeader = false;

	// If the user is a manager or owner and the learnerId is not the same as the user's userId, show the manager header,
	// this is to allow managers to view the call page of other users
	// learnerId should be defined if this is the case
	if (isManagerOrOwner && user?.userId !== learnerId && learnerId) {
		showManagerHeader = true;
	}

	return (
		<TourProvider
			steps={CallPageSteps}
			components={{
				Navigation: () => (
					<OnboardingNavigation feature={OnboardingFeature.CALL_PAGE} />
				),
			}}
		>
			<AppLayout hideSidebar fullWidth>
				<CallEndedReasonModal session={session} />
				<CallHeader showTeam={showManagerHeader} />
				<Stack direction="column" h="100%" spacing={10} px={10} py={5}>
					{showManagerHeader ? (
						<ManagerStoryHeader story={story} userId={user?.userId} />
					) : (
						<Caller
							numberOfRounds={storySessions?.length || 0}
							story={story}
							completedItems={completedItems}
							allItems={allItems}
						/>
					)}
					<CallDetails
						completedItems={completedItems}
						allItems={allItems}
						story={story}
						session={session}
					/>
				</Stack>
			</AppLayout>
		</TourProvider>
	);
};
