import {
	Avatar,
	Box,
	Flex,
	FlexProps,
	Image,
	Stack,
	Text,
} from '@chakra-ui/react';
import { NavButton } from '../../features/home/components/Navigation/NavButton';

import {
	Book,
	CaretDoubleLeft,
	CaretDoubleRight,
	ChartPieSlice,
	ClockCounterClockwise,
	DotsThreeVertical,
	FastForward,
	Flag,
	Gauge,
	Headset,
	House,
	Phone,
	Question,
	Strategy,
	Target,
} from '@phosphor-icons/react';
import { useAuthInfo } from '@propelauth/react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { UserContextMenu } from '../../features/call/components/UserContextMenu';
import { MenuDivider } from '../../features/home/components/Navigation/MenuDivider';
import { useAppSelector } from '../../redux/hooks';
import {
	selectMenuIsOpen,
	setReportIssueModalIsOpen,
	toggleMenu,
} from '../../redux/slice';
import { useIsManager, useIsOwner } from '../hooks/useCheckRole';
import { useIsRecruiting } from '../hooks/useIsRecruiting';
import { useUserOrg } from '../hooks/useUserOrg';

type IProps = FlexProps;

const HELP_CENTER_URL =
	'https://wejam.atlassian.net/servicedesk/customer/portal/1';
const SUPPORT_EMAIL = 'support@wejam.atlassian.net';

const MotionBox = motion(Box);
const maxChars = 10;

export const Sidebar = ({ ...props }: IProps) => {
	const { t } = useTranslation(['home', 'common']);
	const isOpen = useAppSelector(selectMenuIsOpen);
	const dispatch = useDispatch();
	const isOwner = useIsOwner();
	const isManager = useIsManager();
	const isOwnerOrManager = isOwner || isManager;
	const { user: authUser } = useAuthInfo();
	const org = useUserOrg();
	const isRecruiting = useIsRecruiting();

	const onToggle = () => {
		dispatch(toggleMenu());
	};

	const onHelpCenter = () => {
		window.open(HELP_CENTER_URL, '_blank');
	};

	const onReportIssue = () => {
		dispatch(setReportIssueModalIsOpen(true));
	};

	const onContactSupport = () => {
		window.location.href = `mailto:${SUPPORT_EMAIL}`;
	};

	const name = `${authUser?.firstName || ''} ${authUser?.lastName || ''}`;
	const profileImage = authUser?.pictureUrl || '';
	const orgName = org?.orgName || '';

	// Decide whether to display full name or first name only
	const displayName =
		name.length <= maxChars ? name : authUser?.firstName || '';

	return (
		<Box position="relative" pr="10px">
			<MotionBox
				px={2}
				py="5"
				bg="#FAFAFA"
				overflow="hidden"
				height="100vh"
				borderRight={'1px'}
				width={'220px'}
				borderColor={'#E6E6E6'}
				flexShrink={0}
				initial={{ width: isOpen ? '220px' : '70px' }}
				animate={{ width: isOpen ? '220px' : '70px' }}
				transition={{ duration: 0.3 }}
				{...props}
			>
				<Stack
					role="navigation"
					height={'100%'}
					justify="space-between"
					spacing="1"
					width="full"
				>
					<Stack h="100%" spacing="10">
						<Flex
							px="3"
							alignItems={'center'}
							justifyContent={isOpen ? 'start' : 'center'}
						>
							<Image
								w={isOpen ? '75px' : '40px'}
								h={'30px'}
								src={isOpen ? '/logo.svg' : '/logo-icon.svg'}
								alt="Logo"
							/>
						</Flex>
						<Flex
							aria-label="toggle menu"
							justify={'center'}
							alignItems={'center'}
							right={'0px'}
							top={'100px'}
							position={'absolute'}
							w="6"
							h="6"
							bgColor="#FAFAFA"
							borderRadius={'100%'}
							onClick={onToggle}
							cursor={'pointer'}
						>
							{isOpen ? (
								<CaretDoubleLeft weight="bold" color="#757575" size={14} />
							) : (
								<CaretDoubleRight weight="bold" color="#757575" size={14} />
							)}
						</Flex>

						<Stack mt="3" h="100%" justifyContent={'space-between'} spacing="4">
							<Stack spacing="1">
								<MenuDivider text={isOpen ? t('common:practice') : ''} />
								<Stack spacing="1" id="training-center-section">
									<NavButton
										icon={<House weight="bold" size={16} />}
										label={t('home')}
										hideLabel={!isOpen}
										href={'/users/home'}
									/>
									<NavButton
										icon={<FastForward weight="bold" size={16} />}
										label={t('common:progress')}
										hideLabel={!isOpen}
										href={'/progress'}
									/>
									{!isRecruiting && (
										<>
											<NavButton
												icon={<Book weight="bold" size={16} />}
												label={t('all-missions')}
												hideLabel={!isOpen}
												href={'/gym'}
											/>

											<NavButton
												icon={<Strategy weight="bold" size={16} />}
												label={t('common:playbooks')}
												hideLabel={!isOpen}
												href={'/journeys'}
											/>
										</>
									)}
									<NavButton
										icon={<ClockCounterClockwise weight="bold" size={16} />}
										label={t('history')}
										hideLabel={!isOpen}
										href={'/users/history'}
									/>
								</Stack>
								{!isRecruiting && (
									<>
										<MenuDivider text={isOpen ? t('common:insights') : ''} />
										<NavButton
											icon={<Target weight="bold" size={16} />}
											disabled
											hideLabel={!isOpen}
											href=""
											label={t('my-skills')}
										/>
										<NavButton
											icon={<Phone weight="bold" size={16} />}
											disabled
											hideLabel={!isOpen}
											href=""
											label={t('real-calls')}
										/>
									</>
								)}
								{isOwnerOrManager && (
									<>
										<MenuDivider text={isOpen ? t('common:analytics') : ''} />
										<NavButton
											icon={<ChartPieSlice size={16} />}
											label={t('common:activity')}
											hideLabel={!isOpen}
											href={'/dashboard'}
										/>
										<NavButton
											icon={<Target weight="bold" size={16} />}
											hideLabel={!isOpen}
											label={t('team')}
											href={'/teams'}
										/>
										<NavButton
											icon={<Gauge weight="bold" size={16} />}
											disabled
											hideLabel={!isOpen}
											href=""
											label={t('dashboard')}
										/>
									</>
								)}
							</Stack>
							<Flex direction="column" gap={2}>
								<Stack spacing="1" id="help-center-section">
									<NavButton
										icon={<Question weight="bold" size={16} />}
										hideLabel={!isOpen}
										onClick={onHelpCenter}
										label={t('common:helpCenter')}
									/>
									<NavButton
										icon={<Headset weight="bold" size={16} />}
										hideLabel={!isOpen}
										onClick={onContactSupport}
										label={t('common:contactSupport')}
									/>
									<NavButton
										icon={<Flag weight="bold" size={16} />}
										hideLabel={!isOpen}
										onClick={onReportIssue}
										label={t('common:reportIssue')}
									/>
								</Stack>
								<UserContextMenu placement="right" ml={isOpen ? '5' : '2'}>
									<Flex
										cursor={'pointer'}
										justifyContent={!isOpen ? 'center' : 'start'}
										width={isOpen ? '100%' : 'fit-content'}
										border="1px"
										borderRadius={'8px'}
										gap={2}
										px={1}
										pr={2}
										py={1}
										alignItems={'center'}
									>
										<Avatar
											aria-label="profile picture"
											size={'sm'}
											borderRadius={'8px'}
											src={profileImage}
										/>
										{isOpen && (
											<Flex
												flexGrow={1}
												alignItems={'start'}
												direction={'column'}
											>
												<Text
													isTruncated
													maxW="100px"
													fontSize="14px"
													fontWeight="500"
													whiteSpace="nowrap"
													overflow="hidden"
													textOverflow="ellipsis"
												>
													{displayName}
												</Text>
												<Text isTruncated fontSize={'12px'} color="#757575">
													{orgName}
												</Text>
											</Flex>
										)}
										{isOpen && <DotsThreeVertical size={16} />}
									</Flex>
								</UserContextMenu>
							</Flex>
						</Stack>
					</Stack>
				</Stack>
			</MotionBox>
		</Box>
	);
};
