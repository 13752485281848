import { Box, Button, ButtonProps, Flex, Text } from '@chakra-ui/react';
import { Lock } from '@phosphor-icons/react';
import { ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface NavButtonProps extends ButtonProps {
	icon?: ReactNode;
	label: string;
	href?: string;
	onClick?: () => void;
	hideLabel?: boolean;
}

export const NavButton = (props: NavButtonProps) => {
	const location = useLocation();
	const navigate = useNavigate();
	const handleClick = () => {
		if (props.disabled) return;
		if (props.href) {
			navigate(props.href);
		} else if (props.onClick) {
			props.onClick();
		}
	};
	const isActive = props.href ? location.pathname === props.href : false;
	const { icon, label, disabled, hideLabel, href, onClick, ...buttonProps } =
		props;
	const activeStyle = isActive
		? { bg: 'rgba(66, 65, 228, 0.08)', color: 'brand:500' }
		: {};
	const hoverStyle = disabled
		? {}
		: { bg: 'rgba(66, 65, 228, 0.08)', color: 'brand.500' };
	return (
		<Button
			cursor={disabled ? 'not-allowed' : 'pointer'}
			variant="tertiary"
			justifyContent="start"
			fontWeight={400}
			onClick={handleClick}
			_hover={hoverStyle}
			{...buttonProps}
			{...activeStyle}
		>
			<Flex
				w="100%"
				alignItems={'center'}
				justifyContent={hideLabel ? 'center' : 'start'}
				color={isActive ? 'brand.500' : disabled ? 'gray.400' : 'gray.800'}
				_hover={disabled ? {} : { color: 'brand.500' }}
				gap={2}
			>
				<Box flexShrink="0">{icon}</Box>
				{!hideLabel && (
					<Text
						fontSize={'14px'}
						flexShrink={1}
						textAlign={'center'}
						verticalAlign={'center'}
					>
						{label}
					</Text>
				)}
				{disabled && !hideLabel && (
					<Box flexShrink={0}>
						<Lock size={14} />
					</Box>
				)}
			</Flex>
		</Button>
	);
};
