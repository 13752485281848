import {
	Button,
	Flex,
	FormControl,
	Select,
	Stack,
	Text,
	useToast,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Language } from '@jam/api-sdk';
import { useAuthInfo } from '@propelauth/react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ZodType, z } from 'zod';
import { logUserInformation } from '../../../common/utils/logger';
import { useChangeLanguageMutation } from '../../../redux/api/crmApi';

type LanguageForm = {
	language: Language;
};

type LanguageChangeFormProps = {
	language: Language;
};

const LanguageSchema: ZodType<LanguageForm> = z.object({
	language: z.enum(['en', 'de']),
});

export const LanguageChangeForm = (props: LanguageChangeFormProps) => {
	const { t } = useTranslation('settings');
	const [changeLanguage] = useChangeLanguageMutation();
	const { refreshAuthInfo } = useAuthInfo();
	const toast = useToast();
	const {
		register,
		handleSubmit,
		reset,
		formState: { isSubmitting },
	} = useForm<LanguageForm>({
		resolver: zodResolver(LanguageSchema),
		defaultValues: {
			language: props.language,
		},
	});

	const onSubmit = async (data: LanguageForm) => {
		await changeLanguage({ language: data.language });
		await refreshAuthInfo();
		logUserInformation({
			language: data.language,
		});
		toast({
			title: t('language_change_success'),
			status: 'success',
			isClosable: true,
		});
	};

	return (
		<Flex direction={'column'}>
			<Text fontWeight={'medium'} fontSize={'24px'} color={'#757575'}>
				{t('language')}
			</Text>
			<Stack mt="5" spacing={4} maxW={'500px'}>
				<FormControl as="fieldset">
					<Select
						borderRadius={'full'}
						maxW={'350px'}
						{...register('language')}
					>
						<option value="en">English</option>
						<option value="de">German</option>
					</Select>
				</FormControl>
				<Flex mt="4" gap="5">
					<Button
						id="save-language-button"
						borderRadius={'full'}
						type="submit"
						isLoading={isSubmitting}
						px="6"
						bgColor={'brand.500'}
						size="md"
						onClick={() => {
							void handleSubmit(onSubmit)();
						}}
					>
						{t('save')}
					</Button>
					<Button
						type="reset"
						color={'black'}
						bg="transparent"
						colorScheme="red"
						size="md"
						onClick={() => {
							reset();
						}}
					>
						{t('cancel')}
					</Button>
				</Flex>
			</Stack>
		</Flex>
	);
};
