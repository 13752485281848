import {
	Modal,
	ModalOverlay,
	ModalContent,
	Text,
	ModalBody,
	ModalFooter,
	Button,
	Flex,
	Box,
	Divider,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

type LeaveWarningModalProps = {
	isOpen: boolean;
	onExit: () => void;
	onContinue: () => void;
};

export const LeaveWarningModal = ({
	onExit,
	onContinue,
	isOpen,
}: LeaveWarningModalProps) => {
	const { t } = useTranslation('call', { keyPrefix: 'leave_warning_modal' });
	return (
		<Modal size={'md'} isOpen={isOpen} onClose={onContinue}>
			<ModalOverlay />
			<ModalContent>
				<ModalBody>
					<Flex
						py="4"
						gap={3}
						justifyContent={'center'}
						alignContent={'center'}
						direction={'column'}
						textAlign={'center'}
					>
						<Text fontSize={'24px'} fontWeight={'500'}>
							{t('title')}
						</Text>
						<Text color="#757575" fontWeight={'16px'} lineHeight={'24px'}>
							{t('text')}
						</Text>
					</Flex>
				</ModalBody>
				<Divider />
				<ModalFooter>
					<Flex w="100%" gap="3">
						<Box flex={1}>
							<Button
								w={'full'}
								id="exit-button"
								borderRadius={'full'}
								type="submit"
								color={'#4241E4'}
								bg={'white'}
								_hover={{ bg: 'white' }}
								border={'1px'}
								borderColor={'#4241E4'}
								px="6"
								onClick={onExit}
							>
								{t('exit_mission')}
							</Button>
						</Box>
						<Box flex={1}>
							<Button
								w={'full'}
								id="keep-practicing-button"
								borderRadius={'32px'}
								gap={'16px'}
								border={'1px'}
								_hover={{ bg: 'brand' }}
								onClick={onContinue}
							>
								{t('keep_practicing')}
							</Button>
						</Box>
					</Flex>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
