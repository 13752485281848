import { useEffect, useState } from 'react';

const mobileDevices = ['iPhone', 'Android'];
const maxMobileWidthPx = 767;

export function useIsMobile(): boolean {
	const [isMobile, setIsMobile] = useState<boolean>(false);

	useEffect(() => {
		function checkIsMobile() {
			// Check by userAgent regex
			const userAgent =
				typeof navigator === 'undefined' ? '' : navigator.userAgent;
			const testAgentRegex = new RegExp(mobileDevices.join('|'), 'i');
			const isMobileDevice = testAgentRegex.test(userAgent);

			// Check by screen size
			const isSmallScreen = window.matchMedia(
				`(max-width: ${maxMobileWidthPx}px)`
			).matches;

			setIsMobile(isMobileDevice || isSmallScreen);
		}

		checkIsMobile();

		window.addEventListener('resize', checkIsMobile);
		return () => window.removeEventListener('resize', checkIsMobile);
	}, []);

	return isMobile;
}
