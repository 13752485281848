import { Input } from '@chakra-ui/react';
import { PreparedFilteringOptions } from '../../types/FilteringTypes';
import { AppSelectProps, Select } from '../core/select/Select';

interface Props {
	filter: PreparedFilteringOptions | null;
}

export const FilteringElement = ({ filter = null }: Props) => {
	if (!filter) {
		return null;
	}

	switch (filter.type) {
		case 'select':
			return (
				<Select
					value={filter.value}
					placeholder={filter.placeholder ?? ''}
					onChange={(e) => filter.onChange(e.target.value)}
					maxW="fit-content"
					{...((filter?.componentProps ?? {}) as AppSelectProps)}
				>
					{filter.options?.map((option, index) => (
						<option key={index} value={option.value}>
							{option.label}
						</option>
					))}
				</Select>
			);
		case 'text':
			return (
				<Input
					maxW="fit-content"
					value={filter.value}
					onChange={(e) => filter.onChange(e.target.value)}
					placeholder={filter.placeholder ?? ''}
				/>
			);
		case 'date':
			return (
				<Input
					type="date"
					maxW="fit-content"
					value={filter.value}
					onChange={(e) => filter.onChange(e.target.value)}
					placeholder={filter.placeholder ?? ''}
				/>
			);
		default:
			return null;
	}
};
