import { Box, Flex, Image, useDisclosure } from '@chakra-ui/react';

import { SchemaStoryResponseDto } from '@jam/api-sdk';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
	useLazyGetStoryLastSessionQuery,
	useLazyGetStorySessionsQuery,
} from '../../../redux/api/sessionApi';
import { setHasUserMadeCall } from '../../../redux/slice';
import { useActiveCallTabIndex } from '../hooks/useActiveCallTabIndex';
import { useActiveSession } from '../hooks/useActiveSession';
import { useShouldAskForFeedback } from '../hooks/useShouldAskForFeedback';
import { CallerButtons } from './CallerButtons';
import { CallerHeader } from './CallerHeader';
import UserFeedbackModal from './UserFeedbackModal';

type CallerProps = {
	numberOfRounds: number;
	story: SchemaStoryResponseDto;
	completedItems: number;
	allItems: number;
};

export const Caller = ({
	numberOfRounds,
	story,
	completedItems,
	allItems,
}: CallerProps) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { setActiveSession } = useActiveSession();
	const dispatch = useDispatch();
	const storyId = story.id;

	const askForFeedback = useShouldAskForFeedback();
	const [getLastStoryQuery] = useLazyGetStoryLastSessionQuery();
	const [getStorySessionsQuery] = useLazyGetStorySessionsQuery();
	const { setActiveCallTabIndex } = useActiveCallTabIndex();

	useEffect(() => {
		if (askForFeedback) {
			onOpen();
		}
	}, [askForFeedback]);

	useEffect(() => {
		return () => {
			dispatch(setHasUserMadeCall(false));
		};
	}, []);

	const onFinishCallHandler = useCallback(async (): Promise<void> => {
		// remove session from the URL we need to load last one
		setActiveSession(null);
		await getLastStoryQuery(storyId);
		await getStorySessionsQuery(storyId);
		dispatch(setHasUserMadeCall(true));
		setActiveCallTabIndex(2);
	}, []);

	return (
		<Box mt={4}>
			<UserFeedbackModal storyId={storyId} isOpen={isOpen} onClose={onClose} />
			<Flex
				bg="#FCFCFC"
				border={'1px'}
				borderRadius={'24px'}
				borderColor={'#D9D9D9'}
			>
				<Image
					w="150px"
					h="150px"
					borderRadius={'24px'}
					src={
						story.persona.avatar_file.sizes.thumbnail?.url ??
						story.persona.avatar_file.url
					}
				/>
				<Flex mt="6" w="100%" gap="4" px="4" justifyContent={'space-between'}>
					<CallerHeader
						story={story}
						allItems={allItems}
						completedItems={completedItems}
						numberOfRounds={numberOfRounds}
					/>
					<CallerButtons
						story={story}
						numberOfRounds={numberOfRounds}
						onFinishCall={onFinishCallHandler}
					/>
				</Flex>
			</Flex>
		</Box>
	);
};
