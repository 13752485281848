import { useQueryParam, withDefault, StringParam } from 'use-query-params';

export const useActiveSession = () => {
	const [activeSession, setActiveSession] = useQueryParam<string | null>(
		'sessionId',
		withDefault(StringParam, null)
	);

	return { activeSession, setActiveSession };
};
