import { useAuthInfo } from '@propelauth/react';
import { OrgMemberInfo } from '../types/AuthTypes';

/**
 * checks if the user is a manager or owner in any of the organizations
 * @returns the orgId of the organization where the user is a manager or owner
 */
export const useUserOrg = (): OrgMemberInfo | undefined => {
	const authInfo = useAuthInfo();
	const orgs = authInfo.orgHelper?.getOrgs();

	if (!orgs) return;

	return orgs[0];
};
