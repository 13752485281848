import { Text } from '@chakra-ui/react';
import { useAuthInfo } from '@propelauth/react';
import { useTranslation } from 'react-i18next';
import { AppLayout } from '../common/components/AppLayout';
import { Salutation } from '../features/home/components/Salutation';
import { PersonalSettingsTab } from '../features/settings/components/PersonalSettingsTab';

export const SettingsPage = () => {
	const { t } = useTranslation('settings');
	const { user } = useAuthInfo();

	if (!user) return null;

	return (
		<AppLayout>
			<Salutation text={t('settings')} />
			<Text
				fontSize={'24px'}
				fontWeight={'500'}
			>{`${user.firstName ?? ''} ${user.lastName ?? ''}`}</Text>
			<Text mt="3" fontWeight={'light'}>
				{t('account-description')}
			</Text>
			<PersonalSettingsTab />
		</AppLayout>
	);
};
