import { useGetOrganizationUsersQuery } from '../../redux/api/crmApi';

export const useGetUserById = (userId: string | undefined | null) => {
	const { data } = useGetOrganizationUsersQuery(undefined, {
		skip: !userId,
	});

	if (!data) return null;

	const user = data.find((u) => u.id === userId);

	return user;
};
