import React from 'react';
import { Text } from '@chakra-ui/react';

export const UserTeamCell: React.FC<{
	row: {
		teams?: { name: string }[];
	};
}> = ({ row }) => {
	return <Text>{row.teams?.[0]?.name ?? ''}</Text>;
};
