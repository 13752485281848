import {
	useLogoutFunction,
	useRedirectFunctions,
	withAuthInfo,
} from '@propelauth/react';
import React from 'react';
import { AuthInfo } from '../common/types/AuthTypes';

const AuthPageComponent: React.FC<AuthInfo> = (props: AuthInfo) => {
	const { redirectToLoginPage, redirectToSignupPage, redirectToAccountPage } =
		useRedirectFunctions();
	const logoutFunction = useLogoutFunction();

	const handleLogout = () => {
		// You can await the logoutFunction or handle the promise as needed
		logoutFunction(true).catch((error) => {
			// eslint-disable-next-line no-console
			console.error('Logout failed', error);
		});
		// Additional logic after logout if needed
	};

	return (
		<div>
			{props.isLoggedIn ? (
				<>
					<p>You are logged in as {props.user?.email}</p>
					<button onClick={() => redirectToAccountPage()}>Account</button>
					<button onClick={handleLogout}>Logout</button>
				</>
			) : (
				<>
					<p>You are not logged in</p>
					<button
						onClick={() =>
							redirectToLoginPage({
								postLoginRedirectUrl: window.location.href,
							})
						}
					>
						Login
					</button>
					<button onClick={() => redirectToSignupPage()}>Signup</button>
				</>
			)}
		</div>
	);
};
const AuthPage = withAuthInfo<AuthInfo>(AuthPageComponent);
export default AuthPage;

// export default withAuthInfo<<AuthInfo>>(AuthPage);
