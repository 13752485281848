import { Box, Flex, Text } from '@chakra-ui/react';
import { UserWidgets } from '../../../common/components/UserWidgets';

type SalutationProps = {
	text: string;
};

export const Salutation = ({ text }: SalutationProps) => {
	return (
		<Box w="100%" mb="5" borderRadius={'md'} role="heading">
			<Flex
				direction={'row'}
				gap={10}
				justifyContent={'space-between'}
				alignItems={{ base: 'column', lg: 'center' }}
				w="100%"
			>
				<Flex direction={'column'} gap={'24px'}>
					<Text fontSize="3xl" fontWeight="medium">
						{text}
					</Text>
				</Flex>
				<UserWidgets />
			</Flex>
		</Box>
	);
};
