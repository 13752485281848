import { useGetNextStories } from '../../features/home/hooks/useGetNextStories';

export const useGetFirstNotCompletedStory = () => {
	const { upcomingStories, isLoading } = useGetNextStories();

	return {
		activeStory: upcomingStories?.[0] ?? null,
		isLoading,
	};
};
